/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import './content-watch.css';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../layout/breadcrumb';
import Paper from '@material-ui/core/Paper';
import shareImg from '../../public/img/share.svg';
import minatureIcon from '../../public/img/videoplayer/minature.png';
import { ContentService } from './Service/ContentService';
import loaderNewGif from '../../public/img/new-loader.png';
import { useNavigate, useLocation } from "react-router-dom";
import { BlackTriangle, BlackTriangleBrand, BlackTriangleVideoTitle, BlackTriangleShowMore, BlackTriangleReplacer } from "../shared/BlackTriangle";
import UpNext from "./components/upNext";
import PlayList from "./components/PlayList";
import RelatedContent from "./components/RelatedContent";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AttemptQuiz from './components/AttemptQuiz';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import PIInfo from '../shared/PIInfo';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightArrowIcon from '../../public/img/right-arrow.svg';
import LeftArrowIcon from '../../public/img/left-arrow.svg';
import Hidden from '@material-ui/core/Hidden';
import codeCopied1 from "../../public/img/codeCopied.png";
import meetSpeakerIcon from '../../public/img/meetSpeakerIcon.svg';
import { SessionService } from '../login/Services/SessionService';
import countryListJson from '../../CountryPassport/CountryPassport.json';
import { HomeServiceBaseMatchingModel } from '../home/Service/homeService';
import { UserBrandService } from '../home/Service/userBrandService';
import * as jose from 'jose';
import { setAffiliate } from '../../Session/AffiliateSlice';
import {useQuery} from 'react-query'
// import new component for LILLYPLAY-3571 
import RenderHTML from './components/RenderHTML';
import redirectModalMessage from '../../language-translation/redirect-modal.json'

import axios from 'axios';
const ContentWatch = (props) => {
    let affiliateSelector = useSelector(state => state.affilaiteSlice);
    let categoriesSelector = useSelector(state => state.categorySlice);
    let contentService = new ContentService();
    const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
    const [speakerName, SetSpeakerName] = React.useState('');
    const [speakerBio, SetSpeakerBio] = React.useState('');
    const [speakerImg, SetSpeakerImg] = React.useState('');
    // LILLYPLAY-3571 : moving the modal states to new component 
    // const [redirectLink, SetRedirectLink] = React.useState('');
    // const [redirectWaringModal, SetRedirectWaringModal] = React.useState(false);
    const [navigatePopup, setNavigatePopup] = React.useState(false);
    const [speakerCVModal, SetSpeakerCVModal] = React.useState(false);
    const [isMandatory, SetIsMandatory] = React.useState(false);
    const [favID, setFavID] = React.useState(0);
    const [favImg, setFavImg] = React.useState('favorite.svg');
    const [likeImg, setLikeImg] = React.useState('like.svg');
    const [contentID, setContentID] = React.useState('');
    const [contentType, setContentType] = React.useState('');
    const [contentTitle, SetContentTitle] = React.useState('');
    const [videoDescription, SetvideoDescription] = React.useState('');
    const [imagearray, setimagearray] = React.useState([]);
    const [thumbnailImage, SetThumbnailImage] = React.useState('');
    const [speakers, SetSpeakers] = React.useState([]);
    const [tags, Settags] = React.useState([]);
    const [videoview, setVideoview] = React.useState(false);
    const [likes, SetLikes] = React.useState(0);
    const [kalturaId, SetKalturaId] = React.useState('');
    const [userId, SetUserId] = React.useState('');
    const [month, SetMonths] = React.useState('');
    const [totalViews, SettotalViews] = React.useState(0);
    const [isHCP, setHCPValue] = React.useState(false);
    const navigate = useNavigate();
    const [cancelled, setCancelled] = React.useState(false);
    const [videoEnd, setVideoEnd] = React.useState(false);
    const [PlaylistEnd, setPlaylistEnd] = React.useState(false);
    const { kaltura_id, content_id } = useParams();
    const [affiliateId, SetAffiliateId] = React.useState(0);
    const [showMore, SetshowMore] = React.useState(true);
    const [showMoreactive, SetshowMoreactive] = React.useState(false);
    const [isLikeActive, SetIsLikeActive] = React.useState(false);
    const [category1Ids, SetCategory1Ids] = React.useState([]);
    const [productCharacteristics, SetProductCharacteristics] = React.useState([]);
    const [countryUpNext, SetcountryUpNext] = React.useState(false);
    const [materials, SetMaterials] = React.useState([]);
    const [relatedVideosFetchStatus, SetRelatedVideosFetchStatus] = React.useState(false);
    const [relatedVideos, SetRelatedVideos] = React.useState([]);
    const [userThumbnails, SetuserThumbnails] = React.useState([]);
    const [upNextVideo, setUpNextVideo] = React.useState();
    const [upNextVideoTitle, setUpNextVideoTitle] = React.useState();
    const [isPlaylist, SetIsPlaylist] = React.useState(false);
    const [playlistID, SetPlaylistID] = React.useState(0);
    const [playlistTitle, SetPlaylistTitle] = React.useState("");
    const [_playlistVideos, SetPlaylistVideos] = React.useState([]);
    const [isSeries, SetIsSeries] = React.useState(0);
    const [quizId, SetquizId] = React.useState('');
    const [pdfThumb, SetpdfThumb] = React.useState('');
    const [actualPDF, SetactualPDF] = React.useState('');
    const [startedDate, SetstartedDate] = React.useState(new Date());
    const [oktaId, setoktaId] = React.useState("NA");
    const [fullName, setFullName] = React.useState("NA");
    const [quizEmail, setQuizEmail] = React.useState("NA");
    const [sharable, setSharable] = React.useState("no");
    const [sharableLink, setSharableLink] = React.useState("");
    const [cid, SetCid] = React.useState("");
    const [openEmbedModal, setEmbedOpenModal] = React.useState(false);
    const [upNextContentId, SetUpNextContentId] = React.useState('');
    const [thumbnail, setthumbnail] = React.useState([]);
    const [actualfile, setactualfile] = React.useState([]);
    const [breadcrumbDetails, setBreadcrumbDetails] = React.useState([]);
    const [contentClassValue, setcontentClassValue] = React.useState("");
    const [kdpBindingCheck, SetKDPBindingCheck] = React.useState(true);
    const [viewCount, setviewCount] = React.useState('0');
    const [viewwatchpercentage, setviewwatchpercentage] = React.useState('0');
    const [playListContentId, setplayListContentId] = React.useState('');
    const [hcpEmail, SetHcpEmail] = React.useState('');
    const [hcpCC, SetHcpCC] = React.useState('');
    const [copyClicked, SetcopyClicked] = React.useState(false);
    const [widgetCallback, SetWidgetCallback] = React.useState(false);
    const [playlistUpdateStateCheck, SetPlaylistUpdateStateCheck] = React.useState(0);
    const [upNextUpdateCheck, SetUpNextUpdateCheck] = React.useState(false);
    const [duration, SetDuration] = React.useState(0);
    const [checkQuizMandatory, SetCheckQuizMandatory] = React.useState(false);
    const [createdBy, SetCreatedBy] = React.useState('');
    const [recordingInstant, SetRecordingInstant] = React.useState('');
    const slides = 100;
    var maxWidth = Math.max(window.screen.width, window.innerWidth);
    const NumberIncrement = (stateValue) => {
        return stateValue + 1;
    }
    const ContentIdModifier = (contentId) => {
        if (contentId.includes('&')) {
            let newId = contentId.split('&');
            if (Array.isArray(newId) && newId.length > 0) {
                return newId[0];
            }
        }
        if (contentId.includes('?')) {
            let newId = contentId.split('?');
            if (Array.isArray(newId) && newId.length > 0) {
                return newId[0];
            }
        }
        return contentId;
    }

    async function handleNavigate(){
        setNavigatePopup(true);
        setTimeout(()=>{
            window.location.href = '/home';
        }, 5000)
    }
    

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: GetSetPlaylistData
    @Parameters: playlistId: String
    @Return: N/A
    @statement: Fetch the playlist details based on the playlist id
    @API-return: When the user opens a playlist, all the playlist metadata should be fetched based on the metadata on the playlist id from the url.
    */
    async function GetSetPlaylistData(playlistId) {
        if (sessionState) {
            if (sessionState.sessionValue) {
                if (sessionState.sessionValue.payload) {
                    // if()
                } else {
                    return;
                }
            } else {
                return;
            }
        } else {
            return;
        }
        SetIsPlaylist(true);
        props.SetIsPlaylist(true);
        SetPlaylistID(playlistId);
        let playlistData = await contentService.GetPlaylistData(playlistId);
        let playlistVideos = await contentService.GetPlayListVideos(playlistData.content_ids);
        let newPlaylistVideos = await GetVideosWithSpeakersName(playlistVideos);
        let kalturaIdCheck = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
        if (kalturaIdCheck.indexOf('-') > -1) {
            let urlParams = kalturaIdCheck.split('-');
            kalturaIdCheck = urlParams[0];
        }
        let statusNotSubmittedCount  = 0;
        if (playlistVideos?.length > 0) {
            for (let ip = 0; ip < playlistVideos?.length; ip++) {
                if (playlistVideos[ip]?.status === 'draft' || playlistVideos[ip]?.status === 'deleted') {
                    statusNotSubmittedCount ++;
                }
            }

        } else {
            handleNavigate()
        }
        if (statusNotSubmittedCount  == playlistVideos?.length) {
            handleNavigate()
        }
        let videoIds = playlistData.content_ids;
        let newjsondata = [];
        let kalturaIdList = [];
        if (newPlaylistVideos.length > 0) {
            for (let i = 0; i < videoIds.length; i++) {
                for (let j = 0; j < newPlaylistVideos.length; j++) {
                    if (videoIds[i] === newPlaylistVideos[j].content_id && newPlaylistVideos[j]?.status == 'submitted' && CheckIfVideoIsEligibleToWatch(newPlaylistVideos[j], true)) {
                        newjsondata.push(newPlaylistVideos[j]);
                        kalturaIdList.push(newPlaylistVideos[j].kaltura_id);
                    }
                }
            }

            if (newjsondata.length !== 0){
                if (!kalturaIdList.includes(kalturaIdCheck)) {
                    SetKalturaId(newjsondata[0].kaltura_id)
                    let linkRedirect = '/content-watch/' + newjsondata[0].kaltura_id + '-' + playlistId + '/' + playlistId;
                    window.location.href = linkRedirect
                }
            } else{
                handleNavigate()
            }
        }
        SetPlaylistTitle(playlistData.title);
        SetIsSeries(playlistData.is_series);
        SetPlaylistVideos(newjsondata);
        let user_thumbnails = await GetVideosWithThumbnails(newjsondata);
        SetuserThumbnails(user_thumbnails);
        if (playlistData && playlistData.quiz_id != "null") {
            if (playlistData.quiz_id) {
                SetquizId(playlistData.quiz_id.quiz_id);
            } else {
                SetquizId('');

            }
        } else {
            SetquizId('');
        }
        SetPlaylistUpdateStateCheck(NumberIncrement(playlistUpdateStateCheck))
        
        return newjsondata;
    }

    //Function to add Thumbnails of Playlsits
    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: GetVideosWithThumbnails
    @Parameters: playlistVideos: String[]
    @Return: N/A
    @statement: Fetches the thumbnails from s3 based on the playlist id and the thumbnail location in s3 bucket.
    @API-return: When the user opens a playlist he should be able to see the thumbnails that been custom uploaded based on the details stored by the publisher/internal users.
    */
    const GetVideosWithThumbnails = async (playlistVideos) => {
        let newArr = {};
        if (playlistVideos.length > 0) {
            for (let i = 0; i < playlistVideos.length; i++) {
                if (playlistVideos[i].user_thumbnail) {
                    if (playlistVideos[i].user_thumbnail.length > 0) {
                        contentService.GetImageFromURL(playlistVideos[i].user_thumbnail).then((data) => {
                            if (data) {
                                SetThumbnailImage(data);
                                newArr['image' + playlistVideos[i].content_id] = data
                                newArr = JSON.parse(JSON.stringify(newArr))
                                setimagearray(...imagearray, newArr);
                            }

                        }).catch((err) => { })

                    } else {
                        if (playlistVideos[i].thumbnail) {
                            SetThumbnailImage(playlistVideos[i].thumbnail);
                        }
                    }
                } else {
                    if (playlistVideos[i].thumbnail) {
                        SetThumbnailImage(playlistVideos[i].thumbnail);
                    }
                }
            }
        }
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: GetVideosWithSpeakersName
    @Parameters: playlistVideos: String[]
    @Return: N/A
    @statement: Fetches the speaker names in the order of which the content was stored in the playlist.
    @API-return: When the user opens a playlist, the user should be able to see the speaker name based on the current content that he's watching.
    */
    const GetVideosWithSpeakersName = async (playlistVideos) => {
        let speakersIDlist = [];
        if (playlistVideos.length > 0) {
            for (let i = 0; i < playlistVideos.length; i++) {
                if (playlistVideos[i].speakers_id != null) {
                    speakersIDlist.push(playlistVideos[i].speakers_id);
                }
            }
        }
        let speakerMasterList = [].concat.apply([], speakersIDlist);
        let speakerList = speakerMasterList.length > 0 ? await contentService.GetSpeakersById(speakerMasterList) : [];
        let speakerDict = {};
        if (speakerList.length > 0) {
            for (let i = 0; i < speakerList.length; i++) {
                speakerDict[speakerList[i].speaker_id.toString()] = speakerList[i].name;
            }
        }
        if (playlistVideos.length > 0) {
            for (let i = 0; i < playlistVideos.length; i++) {
                let videoSpeakerArr = playlistVideos[i].speakers_id;
                let speakerNames = [];
                if (videoSpeakerArr != null) {
                    for (let j = 0; j < videoSpeakerArr.length; j++) {
                        let videoSpeakerID = videoSpeakerArr[j];
                        speakerNames.push(speakerDict[videoSpeakerID]);
                    }
                }
                playlistVideos[i].speakers_id = speakerNames;
            }
        }
        let current_content_id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        playlistVideos = playlistVideos.filter(x=> x.content_id !== current_content_id )
        let final_playlistVideos = Array.from(new Set(playlistVideos.map(JSON.stringify))).map(JSON.parse);
        return final_playlistVideos;
    }


    // Added Chnages 3368
    const getContentData = async (kalturaId, contentId, navigate) => {
        try {
            if (!localStorage.getItem('jwtToken')) {
                return null;
                // throw new Error('Access Token Not Available');
            }
            
            // let data = await axios.post(this.baseUrl + '/content/api/video/GetVideosByQuery?kaltura_id=' + kalturaId, {}, this.authHeader);
            let data = contentId.includes('PL') ? await contentService.GetPlaylistbyId(contentId) : (!isLoading) ? finalContentData : null;
            if (contentId.includes('PL')) {
                data = { data: data };
            }
            if (Array.isArray(data.data) && data.data.length > 0) {
                for (let i = 0; i < data.data.length; i++) {
                    let video = data.data[i];
                    if (contentId.includes('PL')) {
                        let playListData = await contentService.GetPlaylistData(contentId);
                        let playListVideos = await contentService.GetPlayListVideos(playListData.content_ids);
                        if (Array.isArray(playListVideos)) {
                            for (let k = 0; k < playListVideos.length; k++) {
                                let playListVideo = playListVideos[k];
                                if (playListVideo && playListVideo.kaltura_id == kalturaId) {
                                    if (playListVideo.status != 'deleted' && playListVideo.status != 'draft') {
                                        return playListVideo
                                    }
    
                                }
                            }
                        }
    
                    } else {
                        if (video.content_id == window.location.pathname.split('/')[3]) {
                            if (video.status != 'deleted' && video.status != 'draft') {
                                return video;
                            } else {
                                await handleNavigate()
                            }
                        } else {
                            await handleNavigate()
                        }
                    }
    
                }
            } else {
                handleNavigate()
            }
            // navigate('/');
            return null;
        } catch (err) { return null }
    }

    const getAffiliateId = async(affiliateName, dispatch, selector, setAffiliate)=>{
        if(affiliateName){
            let trimmed_country = affiliateName.split(' ');
            let final_country = affiliateName.includes(' ') ? trimmed_country[0]+"%20"+trimmed_country[1] : affiliateName;
            if(selector){
                if( selector.affiliates){
                    if(Array.isArray(selector.affiliates.payload)){
                        if(selector.affiliates.payload.length>0){
                            if(selector.affiliates.payload[0].country_name == final_country){
                                return selector.affiliates.payload[0]
                            }
                        }
                    }
                }
            }
            let data = !isLoadingFinalAffiliateIdData ? finalAffiliateIdData : null
            return data?.data[0];
        } else {
            return null;
        }
    }

    const fetchContentData = async (content_id) => {
        let data = await axios.post(contentService.baseUrl + '/content/api/video/GetVideosByQueryRaw?content_id=' + ContentIdModifier(content_id), {}, contentService.authHeader)
        return (data);
    }   

    const fetchAffiliateIdData = async (final_country) => {
        let data = await axios.post(contentService.baseUrl + '/management/api/user/GetAffiliateByQuery?country_name=' + final_country, {}, contentService.authHeader);
        return (data);
    }   

    const {data : finalContentData, isLoading} = useQuery({
        queryKey: [kaltura_id, 'contentData'],
        queryFn: () => fetchContentData(content_id),

    })

    const {data : finalAffiliateIdData, isLoading: isLoadingFinalAffiliateIdData} = useQuery({
        queryKey: [content_id, 'affiliateData'],
        queryFn: () => {
            let affiliateName
            if (localStorage.getItem('country')) {
                affiliateName = localStorage.getItem('country')
            }
            let trimmed_country = affiliateName?.split(' ');
            let final_country = affiliateName.includes(' ') ? trimmed_country[0]+"%20"+trimmed_country[1] : affiliateName;
            return fetchAffiliateIdData(final_country)
        }

    })

    /** Breadcrumb for content watch section  */
    let breadcrumbValue = "";
    let urlId = kalturaId;
    let BreadcrumbData = [];
    let classValue = "";
    React.useEffect(() => {
        if (isPlaylist) {
            breadcrumbValue = props.translations['Watch Playlist'] ? props.translations['Watch Playlist'] : 'Watch Playlist';
            urlId = kalturaId + '-' + playlistID;
        }
        else {
            if (contentType !== "") {
                if (contentType === 'podcast') {
                    breadcrumbValue = "Listen to Podcast";
                } else if (contentType === 'video') {
                    breadcrumbValue = "Watch Video";
                }

            }
        }
        classValue = (contentType === 'podcast') ? 'watch-podcastTag' : 'watch-videoTag';
        BreadcrumbData = [
            { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
            { value: breadcrumbValue, path: '/content-watch/' + urlId }
        ];
        setBreadcrumbDetails(BreadcrumbData)
        setcontentClassValue(classValue)
        
    }, [contentType, isPlaylist])


    const { state } = useLocation();
    let selectorValue = useSelector(state => state.session);
    const dispatch = useDispatch();
    const [sessionId, SetsessionId] = React.useState('');
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [activeContentId, SetactiveContentId] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [playerIdState, SetPlayerId] = React.useState('');
    const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
    const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
    const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
    const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);
    const [brandCompletion, SetBrandCompletion] = React.useState(false);
    const [stleString, SetStyleString] = React.useState("width: 560px;height: 395px");
    const [kalturaPlayerInstance, setKalturaPlayerInstance] = React.useState('');
    const playerRef = React.useRef(null);
    const kalturaPlayerRef = React.useRef(null);
    const handleClose = () => { setOpenModal(false); };
    React.useEffect(() => {
        SetSessionState(selectorValue);
    }, [selectorValue]);
    React.useEffect(() => {
        let kalturaId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
        if (kalturaId.indexOf('-') > -1) {
            let urlParams = kalturaId.split('-');
            kalturaId = urlParams[0];
            let playlistId = '';
            playlistId = urlParams[1];
            GetSetPlaylistData(playlistId);
        }
        getContentData(kalturaId, ContentIdModifier(content_id), navigate).then((data) => {
            if(data){
                if(kalturaId == data?.kaltura_id){
                    if (content_id.includes('PL')) {
                        CheckIfVideoIsEligibleToWatch(data, true);
                    } else {
                        CheckIfVideoIsEligibleToWatch(data, false);
                    }
                } else {
                    handleNavigate()
                }
            }
        });
    }, [sessionState,finalContentData])

    const handleDelConfirmClose = () => {
        setopenDelConfirmModal(false);
    };

    function getUserSelectedBrandData() {
        let userBrandService = new UserBrandService();
        userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData).then((data) => {
            SetBrandCompletion(true);
        }).catch((err) => { });
    }
    const GetAffiliateCodesArray = () => {
        let list = []
        if (sessionState) {
            if (sessionState.sessionValue.payload) {
                if (Array.isArray(sessionState.sessionValue.payload.affiliate)) {
                    let affiliates = sessionState.sessionValue.payload.affiliate
                    for (let i = 0; i < affiliates.length; i++) {
                        if (affiliates[i]) {
                            list.push(affiliates[i].country_code);
                        }
                    }
                }
            }
        }
        return list;
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: GetHomeServiceModel
    @Parameters: specialty: String,profession: String
    @Return: N/A
    @statement: Fetches the content that are only visible to the user if the user's metadata such as speaciality, profession, etc. matches with that of the content.
    @API-return: Fetches the content that are only visible to the user if the user's metadata such as speaciality, profession, etc. matches with that of the content.
    */
    const GetHomeServiceModel = (specialty = '', profession = '') => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginROute = '';
        let specialtyCustomization;
        if (sessionState) {
            if (sessionState.sessionValue.payload) {
                if (sessionState.sessionValue.payload.loginroute) {
                    loginROute = sessionState.sessionValue.payload.loginroute
                }
                if (sessionState.sessionValue.payload.simulationCheck) {
                    localeName = localStorage.getItem('languageSimulation') ?? '';
                }
            }
        }
        if (sessionState) {
            if (sessionState.sessionValue.payload) {
                if (sessionState.sessionValue.payload.decodedToken) {
                    if (sessionState.sessionValue.payload.decodedToken) {
                        let _spec = sessionState.sessionValue.payload.decodedToken.specialty ? sessionState.sessionValue.payload.decodedToken.specialty : "";
                        let _prof = sessionState.sessionValue.payload.decodedToken.profession ? sessionState.sessionValue.payload.decodedToken.profession : sessionState.sessionValue.payload.decodedToken.profession;
                        let brandIdsArr = [];
                        let taIdsArr = [];
                        let indIdsArray = [];
                        if (sessionState.sessionValue.payload.user) {
                            let userTA = sessionState.sessionValue.payload.user.therapeutic_area;
                            let categories = userTA && Array.isArray(userTA) ? userTA : [];
                            let countryTAList = [];
                            let countryCat1Ids = [];
                            let countryCat2Ids = [];
                            if (Array.isArray(sessionState.sessionValue.payload.affiliate)) {
                                for (let k = 0; k < sessionState.sessionValue.payload.affiliate.length; k++) {
                                    if (Array.isArray(sessionState.sessionValue.payload.affiliate[k].therapeutic_area_ids)) {
                                        countryTAList = countryTAList.concat(sessionState.sessionValue.payload.affiliate[k].therapeutic_area_ids);
                                    }
                                    if (sessionState.sessionValue.payload.affiliate[k].speciality_customization) {
                                        if (Array.isArray(sessionState.sessionValue.payload.affiliate[k].category_1_ids)) {
                                            countryCat1Ids = countryCat1Ids.concat(sessionState.sessionValue.payload.affiliate[k].category_1_ids);
                                        }
                                        if (Array.isArray(sessionState.sessionValue.payload.affiliate[k].category_2_ids)) {
                                            countryCat2Ids = countryCat2Ids.concat(sessionState.sessionValue.payload.affiliate[k].category_2_ids);
                                        }
                                    }
                                }
                            }
                            if (categories?.length > 0) {
                                for (let i = 0; categories?.length > i; i++) {
                                    let ta_id = categories[i].therapeutic_area_id;
                                    if (countryTAList.includes(ta_id)) {
                                        taIdsArr.push(ta_id);
                                    }

                                    let brand_id = categories[i].brand_id;
                                    if (countryCat1Ids.includes(brand_id)) {
                                        brandIdsArr.push(brand_id);
                                    }

                                    let ind_id = categories[i].indication_id;
                                    if (countryCat2Ids.includes(ind_id)) {
                                        if (ind_id !== null) {
                                            indIdsArray.push(ind_id);
                                        }
                                    }
                                }
                            }
                        }
                        let taArray = [];
                        if (props.filterId.includes(',')) {
                            taArray = props.filterId.split(',');
                        } else {
                            taArray.push(props.filterId);
                        }
                        if (sessionState?.sessionValue?.payload?.user?.language) {
                            localeName = sessionState?.sessionValue?.payload?.user?.language;
                            if (countryName == 'Switzerland') {
                                if (localeName == 'de-CH') {
                                    localeName = 'German'
                                } else if (localeName == 'fr_CH') {
                                    localeName = 'French'
                                }
                            } else {
                                if (localeName == 'nl-BE') {
                                    localeName = 'Dutch'
                                } else if (localeName == 'fr-BE') {
                                    localeName = 'French'
                                }
                            }
                        }
                        if (sessionState.sessionValue.payload.simulationCheck) {
                            localeName = localStorage.getItem('languageSimulation')
                            if (countryName == 'Switzerland') {
                                if (localeName == 'de-CH') {
                                    localeName = 'German'
                                } else if (localeName == 'fr_CH') {
                                    localeName = 'French'
                                }
                            } else {
                                if (localeName == 'nl-BE') {
                                    localeName = 'Dutch'

                                } else if (localeName == 'fr-BE') {
                                    localeName = 'French'
                                }
                            }
                        }
                        let affiliateArray = GetAffiliateCodesArray();
                        let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
                        let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, _spec, _prof, taArray, 'Active', brandIdsArr, indIdsArray, '', countryName, localeName, loginROute);
                        return model;
                    }
                }
            }
        }
        let taFilter = props.filterId;
        if (typeof taFilter == 'string') {
            taFilter = taFilter.split(',')
        }
        let affiliateArray = GetAffiliateCodesArray();
        let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
        let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData, indIdsArrData, '', countryName, localeName, loginROute);
        return model;
    }

    const URLMaker = async (urlIncoming) => {
        return urlIncoming;
        let uri = new URL(urlIncoming);
        let params = uri.searchParams;
        if (params) {
            let qParam = params.get('q') ? params.get('q') : null;
            if (qParam) {
                let Isplaylist = params.get('playlist') ? params.get('playlist') : false;
                let urlModified = await contentService.GetOldVIdeosHcpDirect(qParam, Isplaylist, qParam);
                return urlModified;
            }
        }
        return urlIncoming;
    }
    const getCountryDetails = (country_name) => {
        if (country_name !== undefined) {
            if (countryListJson.length) {
                for (var i = 0; i < countryListJson.length; i++) {
                    let cntName = countryListJson[i].country_code;//.split(" ").join("").toLowerCase();
                    if (cntName == country_name) {
                        localStorage.setItem('ga_code', countryListJson[i].gtm_code);
                        return countryListJson[i];
                    }
                }
            }
        }
        return '';
    }
    const GenerateUTMParameters = () => {

    }
    React.useEffect(() => {
        contentService.PushVideoID(ContentIdModifier(content_id));
        if (!localStorage.getItem('sessionValue')) {
            // localStorage.setItem('oldUrl', window.location.href);
            contentService.SetHCPDirectCookie(25000);
            let uri = new URL(window.location.href);
            if (uri.pathname.includes('content-watch')) {
                let params = uri.searchParams;
                if (params) {
                    let passportToken = params.get('access_token') ? params.get('access_token') : '';
                    if (passportToken) {
                        contentService.SetHCPDirectCookie(25000);
                        let decoded_accessToken = jose.decodeJwt(passportToken);
                        getCountryDetails(decoded_accessToken.countryCode);
                        let redirectURL = uri.pathname;
                        URLMaker(redirectURL).then((data) => {
                            let navigatePath = '/?access_token=' + passportToken + '&url=' + data;
                            navigate(navigatePath)
                            return;
                        })

                    }
                    else {
                        if (window.location.href.includes('play.lilly.com')) {
                            navigate('/sso')
                        } else {
                            handleNavigate();
                        }
                    }
                } else {
                    if (window.location.href.includes('play.lilly.com')) {
                        navigate('/sso')
                    } else {
                        handleNavigate();
                    }

                }
            }
        } else {
            let loginCountry = localStorage.getItem('loginCountry')
            if (loginCountry) {
                if (loginCountry == "UK") {
                    localStorage.clear()
                    window.location.replace(window.location.origin + '/landing/maintenance/index.html');
                }
            } else {
                contentService.SetHCPDirectCookie(0);
                let params = (new URL(window.location.href)).searchParams;
                let hcpDirectLink = params.get('url') ? params.get('url') : '';
                if (hcpDirectLink.length > 0) {
                    URLMaker(hcpDirectLink).then((data) => {

                        navigate(hcpDirectLink)
                    })
                }
            }

        }
        let mediaFrom = 0;
        let playlistId = '';
        let kalturaId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
        if (kalturaId.indexOf('-') > -1) {
            let urlParams = kalturaId.split('-');
            kalturaId = urlParams[0];
            playlistId = urlParams[1];
            GetSetPlaylistData(playlistId);
            mediaFrom = 0.01;
        } else {
            SetIsPlaylist(false)
        }
        // getContentData(kalturaId, ContentIdModifier(content_id), navigate).then((data) => {
        //     if(data){
        //         if(kalturaId == data?.kaltura_id){
        //             if (content_id.includes('PL')) {
        //                 CheckIfVideoIsEligibleToWatch(data, true);
        //             } else {
        //                 CheckIfVideoIsEligibleToWatch(data, false);
        //             }
        //         } else {
        //             handleNavigate()
        //         }
        //     }
        // });
        if (kalturaId === props.play.kalturaID) {
            mediaFrom = props.play.startTime;
        }
        if (props.positionMainPlayer) {
            if (props.positionMainPlayer > 0) {
                mediaFrom = props.positionMainPlayer;
            }
        }
        getUserSelectedBrandData();
    }, []);

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: CheckProfession
    @Parameters: professionArray: String[], professionName: String,audience_mapping: String[]
    @Return: BOOLEAN
    @statement: Returns true if the content has same profession as the users profession or returns false.
    @API-return: Content should be visible to the user based on the user's profession which should match the content's profession.
    */
    const CheckProfession = (professionArray, professionName, audience_mapping) => {
        if (Array.isArray(professionArray) && Array.isArray(audience_mapping)) {
            let audienceValues = audience_mapping.map(v => v.value);
            if (audienceValues.length > 0) {
                for (let i = 0; i < professionArray.length; i++) {
                    if (professionArray[i]) {
                        if (professionArray[i].profession_name == professionName) {

                            if (audienceValues.includes(professionArray[i].profession_key)) {
                                return true;
                            }
                        }
                    }

                }
            } else {
                return true;
            }
        }
        return false;
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: CheckSpecialty
    @Parameters: specialtyArray: String[], specialtyName: String,specialities: String[]
    @Return: BOOLEAN
    @statement: Returns true if the content has same speciality as the users speciality or returns false.
    @API-return: Content should be visible to the user based on the user's speciality which should match the content's speciality.
    */
    const CheckSpecialty = (specialtyArray, specialtyName, specialities) => {
        if (Array.isArray(specialtyArray) && Array.isArray(specialities)) {
            let specialtyList = [];
            for (let i = 0; i < specialities.length; i++) {
                if (specialities[i]) {
                    if (Array.isArray(specialities[i].value)) {
                        specialtyList = specialtyList.concat(specialities[i].value)
                    }
                }
            }
            if (specialtyList.length > 0) {
                for (let i = 0; i < specialtyArray.length; i++) {
                    if (specialtyArray[i]) {
                        if (specialtyArray[i].specialty_key == specialtyName) {
                            if (specialtyList.includes(specialtyArray[i].specialty_key)) {
                                return true;
                            }
                        }
                    }
                }
            } else {
                return true;
            }
        }
        return false;
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: checkVideoPublishStatus
    @Parameters: loginRoute: String, countryArray: String[],videoStatus: JSON
    @Return: BOOLEAN
    @statement: Check to see if the content is in approved state or not.
    @API-return: When the user watches the content check to see if the content is in active state for the country that he's logged into.
    */
    const checkVideoPublishStatus = (loginRoute, countryArray, videoStatus) => {
        if (loginRoute.toLowerCase() === 'federate') {
            let countryLength = countryArray.length;
            if (countryLength > 0) {
                for (let i = 0; i < countryLength; i++) {
                    if (countryArray[i]) {
                        let countryCodeFromSession = countryArray[i]?.country_code;
                        let approvedStatus = videoStatus[countryCodeFromSession]?.status
                        if (approvedStatus && approvedStatus?.toLowerCase() === 'active') {
                            return true
                        }
                    }
                }
            }

        } else {
            if (countryArray.length > 0) {
                let countryCodeFromSession = countryArray[0]?.country_code;
                let approvedStatus = videoStatus[countryCodeFromSession]?.status;
                if (approvedStatus && approvedStatus?.toLowerCase() === 'active') {
                    return true
                }
            }
        }
        return false;
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: CheckIfVideoIsEligibleToWatch
    @Parameters: videoData: JSON, playlistCheck: boolean
    @Return: BOOLEAN
    @statement: The function checks if the user has all the necessary access based on country, profession, categories and specialities to watch the content
    @API-return: User should be able to watch the content if the content is Active, profession,speicality and categories of the users are matching to that of the content, if not then the user should be redirected to home page
    */
    const CheckIfVideoIsEligibleToWatch = (videoData, playlistCheck = false) => {
        try {
            if (videoData) {
                let model = GetHomeServiceModel();
                if (Array.isArray(videoData?.therapeutic_area_id) && Array.isArray(model?.ta)) {
                    let check = model.ta.filter(v => videoData?.therapeutic_area_id.includes(v));
                    if (Array.isArray(check)) {
                        if (check.length == 0) {
                            // return false;
                        }
                    }
                }
                if (sessionState) {
                    if (sessionState.sessionValue) {
                        if (sessionState.sessionValue.payload) {
                            if (Array.isArray(sessionState.sessionValue.payload.profession) &&
                                Array.isArray(sessionState.sessionValue.payload.specialty)) {
                                let professionList = [];
                                let professionCheck = CheckProfession(sessionState.sessionValue.payload.profession, model.profession, videoData.audience_mapping);
                                let specialtyCheck = CheckSpecialty(sessionState.sessionValue.payload.specialty, model.specialty, videoData.specialities);
                                let publishedCheck = checkVideoPublishStatus(sessionState.sessionValue.payload.loginroute, sessionState.sessionValue.payload.affiliate, videoData.content_approved);
                                if (sessionState.sessionValue.payload.loginroute == 'passport') {
                                    if (!professionCheck || !specialtyCheck || !publishedCheck) {
                                        if (playlistCheck) {
                                            return false;
                                        }
                                        else {
                                            handleNavigate()
                                        }
                                    }
                                } else {
                                    if(!publishedCheck){
                                        if (playlistCheck){
                                            return false;
                                        }
                                        else {
                                            handleNavigate()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return true;
        } catch (err) {
        }

    }
    React.useEffect(() => {
        let list = [];
        let hcp_id = selectorValue?.sessionValue?.payload?.decodedToken?.id;
        let full_name = selectorValue?.sessionValue?.payload?.decodedToken?.firstName + ' ' + selectorValue?.sessionValue?.payload?.decodedToken?.lastName;
        let email = selectorValue?.sessionValue?.payload?.decodedToken?.email;
        setoktaId(hcp_id);
        setFullName(full_name);
        setQuizEmail(email);
        let countryCodeArray = [];
        if (_playlistVideos.length > 0) {
            for (let i = 0; i < _playlistVideos.length; i++) {
                let video = Object.assign({}, _playlistVideos[i]);
                let contentApproved = video.content_approved;
                if (selectorValue) {
                    if (selectorValue.sessionValue.payload) {
                        if (selectorValue.sessionValue.payload.user) {
                            if (selectorValue.sessionValue.payload.user) {
                                if (Array.isArray(selectorValue.sessionValue.payload.affiliate)) {
                                    if (selectorValue.sessionValue.payload.affiliate.length > 0) {
                                        for (let k = 0; k < selectorValue.sessionValue.payload.affiliate.length; k++) {
                                            countryCodeArray.push(selectorValue.sessionValue.payload.affiliate[k].country_code);
                                            let countryCode = selectorValue.sessionValue.payload.affiliate[k].country_code;
                                            if (contentApproved[countryCode]) {
                                                if (contentApproved[countryCode].status == 'Active') {
                                                    if (video.status == "submitted") {
                                                        list.push(video);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }, [_playlistVideos, selectorValue, oktaId, fullName, quizEmail, hcpCC])

    React.useEffect(() => {
        if (props.durationUrlRead) {
            let mediaFrom = 0;
            let playlistId = '';
            let kalturaId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
            if (kalturaId.indexOf('-') > -1) {
                let urlParams = kalturaId.split('-');
                kalturaId = urlParams[0];
                playlistId = urlParams[1];
                mediaFrom = 0.01;
                GetSetPlaylistData(playlistId);
            } else {
                SetIsPlaylist(false)
            }
            if (kalturaId === props.play.kalturaID) {
                mediaFrom = props.play.startTime;
            }
            if (props.positionMainPlayer) {
                if (props.positionMainPlayer > 0) {
                    if (props.customPathHook.includes('content-watch') && props.lastContentWatchURL.includes('content-watch') && props.customPathHook != props.lastContentWatchURL) {
                        mediaFrom = 0;
                    } else {
                        mediaFrom = props.positionMainPlayer;
                    }
                }
            }
            WidgetTrigger(mediaFrom, kalturaId);
            props.SetDurationUrlRead(false);
        }
    }, [kaltura_id, props.durationUrlRead]);

    React.useEffect(()=>{
        KDBBindindgAsyncCal()
    },[finalContentData, finalAffiliateIdData])

    React.useEffect(() => {
        if (selectorValue) {
            if (selectorValue.sessionValue.payload) {
                if (selectorValue.sessionValue.payload.user) {
                    if (selectorValue.sessionValue.payload.user) {
                        SetsessionId(selectorValue.sessionValue.payload.user.id)
                        SetUserId(selectorValue.sessionValue.payload.user.id);
                        SetHcpEmail(selectorValue.sessionValue.payload.user.user_email);
                        SetHcpCC(selectorValue.sessionValue.payload.decodedToken.countryCode)
                        if (Array.isArray(selectorValue.sessionValue.payload.affiliate)) {
                            if (selectorValue.sessionValue.payload.affiliate.length > 0) {
                                props.setCountryCode(selectorValue.sessionValue.payload.affiliate[0].country_code);
                            }
                        }
                    }
                }
            }
        }
    }, [selectorValue, SetsessionId]);
    React.useEffect(() => {
    }, [viewwatchpercentage]);


    const setWatchData = (contID) => {
        let userID = userId;
        if (selectorValue) {
            if (selectorValue.sessionValue.payload) {
                if (selectorValue.sessionValue.payload.user) {
                    if (selectorValue.sessionValue.payload.user) {
                        userID = selectorValue.sessionValue.payload.user.id
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        if (props.mainPlayerId.length > 0) {
            if (props.miniPlayerId.length > 0 || true) {
                let kdpMain = document.getElementById(props.mainPlayerId);
            }
        }
    }, [props.miniPlayerId, props.mainPlayerId]);

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: getCountryDetailsforShare
    @Parameters: country_name: String
    @Return: JSON
    @statement: This function fetches the country JSON based on country name
    @API-return: Fetch all the relevant country details based on the country name that has been passed.
    */
    const getCountryDetailsforShare = (country_name) => {
        if (country_name !== undefined) {
            if (countryListJson.length) {
                for (var i = 0; i < countryListJson.length; i++) {
                    let cntName = countryListJson[i].country; //split(" ").join("").toLowerCase();
                    if (cntName == country_name) {
                        return countryListJson[i];
                    }
                }
            }
        }
        return '';
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: GetUTMParameters
    @Parameters: value: String
    @Return: String
    @statement: This function fetches the UTM parameters from the URL
    @API-return: When the hcp user enter the url with UTM parameters, the same should be saved in the log views table.
    */
    const GetUTMParameters = (value) => {
        try {
            let uri = new URL(window.location.href);
            let params = uri.searchParams;
            if (value) {
                if (params.get(value)) {
                    return params.get(value);
                }
            }
        } catch (err) {
            return '';
        }
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: getDateTime
    @Parameters: N/A
    @Return: String
    @statement: This function converts the date and timestamp saved in database format to readable format.
    @API-return: The user should be able to see the date at which the content was uploaded in readable format as per the standard notion of displaying dates.
    */
    function getDateTime() {
        var d = new Date();
        let datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        return datestring;
    }

    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    const WidgetTriggerV7 = async (mediaFrom, countryData, data, hcp_id, playerId, content_Id, kalturaId, playListData) => {
        // Variable 'playListData' contains the last updated playlist from useEffect() at 1744
        console.log(playListData, 'Required Data');
        let seekBarInit; 
        if (data?.quiz_credit?.is_mandatory) {
            if (localStorage.getItem('HCP') === 'false') {
                seekBarInit = {
                    preventSeekForward:false,
                    preventSeek: true
                };
            }
        } else {
            seekBarInit = {
                disable: true
            };
        }
        let setupVars = {
            targetId: "player-content-widget",
            provider: {
                partnerId: process.env.REACT_APP_KALTURA_PARTNER_ID,
                uiConfId: process.env.REACT_APP_KALTURA_UI_CONF_ID,
            },
            // disableUserCache: false,
            playback: {
                pictureInPicture: false,
                autopause: false,
                autoplay: mediaFrom > 0 ? true : false,
            },
            plugins: {
                "navigation": {
                    disable: true
                },
                "playkit-js-info": {
                    disable: true
                },
                "floating": {
                    disable: true
                },
                "preventSeek": seekBarInit
            }
        }
        let playlistId = '';
        // let playListData;
        if (kaltura_id.indexOf('-') > -1) {
            let urlParams = kaltura_id.split('-');
            playlistId = urlParams[1];
            // playListData = await GetSetPlaylistData(playlistId);
            // console.log("playListData", playListData);
        }
        let kalturaPlayer = window.KalturaPlayer.setup(setupVars);
        setKalturaPlayerInstance(kalturaPlayer);
        if (data?.quiz_credit?.is_mandatory) {
            props.SetQuizMandatoryStatus(data.quiz_credit.is_mandatory);
        } else {
            props.SetQuizMandatoryStatus(false);
        }
        playerRef.current = kalturaPlayer;
        let sessionUserInfo = parseJwt(localStorage.getItem('sessionValue'));
        let timeCurrent = 0;
        let sentPercentage = [];
        window.KalturaPlayer.getPlayers()['player-content-widget'].addEventListener(
            window.KalturaPlayer.getPlayers()['player-content-widget'].Event.Core.TIME_UPDATE, (event) => {
                const percent = parseFloat((kalturaPlayer.currentTime / kalturaPlayer.duration).toFixed(2));
                props.SetPercentageElapsed(percent);
                //Keep Watching Functionality Here.  -- V2 playerUpdatePlayhead & playerUpdatePlayhead.myPluginName
                let time = 0;
                props.SetPositionMainPlayer(kalturaPlayer.currentTime);
                let videoLength = kalturaPlayer.duration;
                let currentPerce = percent;
                timeCurrent = parseInt(kalturaPlayer.currentTime);
                let totalDuration = kalturaPlayer.duration;
                let currentPercent = parseInt((parseInt(kalturaPlayer.currentTime) / parseInt(totalDuration)) * 100);
                setviewwatchpercentage(currentPercent);
                let insertObj = {
                    user_id: sessionUserInfo.user.id,
                    content_id: ContentIdModifier(content_id),
                    playlist_id: playlistId != 0 ? playlistId : null,
                    watched_percent: currentPercent,
                    watched_on: new Date(),
                    country_code: sessionUserInfo.affiliate[0].country_code
                };
                if ((currentPercent === 0 || currentPercent % 2 === 0) && !sentPercentage.includes(currentPercent)) {
                    sentPercentage.push(currentPercent)
                    contentService.PostWatchedContent(insertObj).then(data => {
                    }).catch(err => { })
                }

                //25% Play - GA & LB Entry
                if (percent == 0.25 && !localStorage.getItem(content_id + '25')) {
                    let obj = {
                        log_action: "25%",
                        content_id: ContentIdModifier(content_id),
                        country_code: countryData ? countryData.country_code : '',
                        okta_id: hcp_id,
                        log_event_label: document.baseURI,
                        utm_source: GetUTMParameters('utm_source'),
                        utm_medium: GetUTMParameters('utm_medium'),
                        utm_campaign: GetUTMParameters('utm_campaign'),
                        log_event_date_time: getDateTime()
                    };
                    let gaModel = {
                        event: 'video',
                        eventCategory: 'Video',
                        eventAction: '25%',
                        videoLength: kalturaPlayer.duration,
                        videoId: ContentIdModifier(content_id),
                        videoCurrentTime: kalturaPlayer.currentTime,
                        videoVolume: kalturaPlayer.volume * 100
                    };
                    contentService.PushKDPEvents(gaModel);
                    localStorage.setItem(content_id + '25', true);
                    try {
                        contentService.UpdateLogViews(obj);
                    } catch (err) { }
                }
                //50% Play - GA & LB Entry
                if (percent == 0.50 && !localStorage.getItem(content_id + '50')) {
                    let obj = {
                        log_action: "50%",
                        content_id: ContentIdModifier(content_id),
                        country_code: countryData ? countryData.country_code : '',
                        okta_id: hcp_id,
                        log_event_label: document.baseURI,
                        utm_source: GetUTMParameters('utm_source'),
                        utm_medium: GetUTMParameters('utm_medium'),
                        utm_campaign: GetUTMParameters('utm_campaign'),
                        log_event_date_time: getDateTime()
                    };
                    let gaModel = {
                        event: 'video',
                        eventCategory: 'Video',
                        eventAction: '50%',
                        videoLength: kalturaPlayer.duration,
                        videoId: ContentIdModifier(content_id),
                        videoCurrentTime: kalturaPlayer.currentTime,
                        videoVolume: kalturaPlayer.volume * 100
                    };
                    contentService.PushKDPEvents(gaModel);
                    localStorage.setItem(content_id + '50', true);
                    try {
                        contentService.UpdateLogViews(obj);
                    } catch (err) { }
                }
                //75% Play - GA & LB Entry
                if (percent == 0.75 && !localStorage.getItem(content_id + '75')) {
                    let obj = {
                        log_action: "75%",
                        content_id: ContentIdModifier(content_id),
                        country_code: countryData ? countryData.country_code : '',
                        okta_id: hcp_id,
                        log_event_label: document.baseURI,
                        utm_source: GetUTMParameters('utm_source'),
                        utm_medium: GetUTMParameters('utm_medium'),
                        utm_campaign: GetUTMParameters('utm_campaign'),
                        log_event_date_time: getDateTime()
                    };
                    let gaModel = {
                        event: 'video',
                        eventCategory: 'Video',
                        eventAction: '75%',
                        videoLength: kalturaPlayer.duration,
                        videoId: ContentIdModifier(content_id),
                        videoCurrentTime: kalturaPlayer.currentTime,
                        videoVolume: kalturaPlayer.volume * 100
                    };
                    contentService.PushKDPEvents(gaModel);
                    localStorage.setItem(content_id + '75', true);
                    try {
                        contentService.UpdateLogViews(obj);
                    } catch (err) { }
                }
                if (percent > 0.25 && localStorage.getItem(content_id + '25')) {
                    localStorage.removeItem(content_id + '25')
                }
                if (percent > 0.50 && localStorage.getItem(content_id + '50')) {
                    localStorage.removeItem(content_id + '50')
                }
                if (percent > 0.75 && localStorage.getItem(content_id + '75')) {
                    localStorage.removeItem(content_id + '75')
                }
            })
        let scrubClickTime = kalturaPlayer.currentTime ? kalturaPlayer.currentTime : 0;
        //SEEKING event
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.SEEKING, async (event) => {
            let seekTime = kalturaPlayer.currentTime;
            scrubClickTime = Math.ceil(seekTime);
            // if (data.quiz_credit.is_mandatory) {
            //     if (localStorage.getItem('HCP') === 'false') {
            //         setopenDelConfirmModal(true);
            //     }
            // }
        })
        //SEEKED event
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.SEEKED, async (event) => {
            let seekTime = kalturaPlayer.currentTime;
            let seekedTo = Math.ceil(seekTime);
            // if (seekedTo > scrubClickTime && data.quiz_credit.is_mandatory) {
            //     if (localStorage.getItem('HCP') === 'false') {
            //         setopenDelConfirmModal(true);
            //     }
            // }
        })
        //Play event - GA & LB Entry
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PLAY, async (event) => {
            props.Setplay(prev => ({ ...prev, state: false, startTime: 0, kalturaID: '' }));
            props.SetAutoPlay(true);
            let gaObject = {
                event: 'video',
                eventCategory: 'Video',
                eventAction: 'Play',
                videoLength: kalturaPlayer.duration,
                videoId: ContentIdModifier(content_id),
                videoCurrentTime: kalturaPlayer.currentTime,
                videoVolume: kalturaPlayer.volume * 100,
                totalViews: viewCount,
                videoTime: recordingInstant,
                createdBy: recordingInstant,
            };
            contentService.PushKDPEvents(gaObject);
            let obj = {
                log_action: "Play",
                content_id: ContentIdModifier(content_id),
                country_code: countryData ? countryData.country_code : '',
                okta_id: hcp_id,
                log_event_label: document.baseURI,
                utm_source: GetUTMParameters('utm_source'),
                utm_medium: GetUTMParameters('utm_medium'),
                utm_campaign: GetUTMParameters('utm_campaign'),
                log_event_date_time: getDateTime()
            };
            try {
                await contentService.UpdateLogViews(obj);
            } catch (err) { }
        })
        //Complete/End Event - 
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENDED, async (event) => {
            let gaModel = {
                event: 'video', eventCategory: 'Video',
                eventAction: 'Complete',
                videoLength: kalturaPlayer.duration,
                videoCurrentTime: kalturaPlayer.currentTime,
                videoId: ContentIdModifier(content_id),
                videoVolume: kalturaPlayer.volume * 100
            };
            contentService.PushKDPEvents(gaModel);
            props.SetPositionMainPlayer(0);
            if (data.quiz_credit) {
                setCancelled(true)
                if (data.quiz_credit.is_mandatory) {
                    if (localStorage.getItem('HCP') === 'false') {
                        setOpenModal(true);
                    }
                }
            }
            let obj = {
                log_action: "Video Complete",
                content_id: ContentIdModifier(content_id),
                country_code: countryData ? countryData.country_code : '',
                okta_id: hcp_id,
                log_event_label: document.baseURI,
                utm_source: GetUTMParameters('utm_source'),
                utm_medium: GetUTMParameters('utm_medium'),
                utm_campaign: GetUTMParameters('utm_campaign'),
                log_event_date_time: getDateTime()
            };
            let insertObj = {
                user_id: sessionUserInfo.user.id,
                content_id: ContentIdModifier(content_id),
                watched_percent: 100,
                watched_on: new Date(),
                playlist_id: playlistId != 0 ? playlistId : null,
                country_code: sessionUserInfo.affiliate[0].country_code
            };
            try {
                await contentService.UpdateLogViews(obj);
                contentService.PostWatchedContent(insertObj).then(data => {
                }).catch(err => { })
            } catch (err) { }
            if (kaltura_id.indexOf('-') > -1) {
                let urlParams = kaltura_id.split('-');
                let urlKalturaID = urlParams[0];
                console.log("playListData--", playListData);
                if (playListData?.length) {
                    let lastVideoKalturaID = playListData[playListData.length - 1].kaltura_id;
                    if (lastVideoKalturaID === urlKalturaID) {
                        setVideoEnd(true);
                        setPlaylistEnd(true);
                        setCancelled(false);
                    } else {
                        let nextVideoindex = 0;
                        for (let i = 0; i < playListData.length; i++) {
                            if (playListData[i].kaltura_id === urlKalturaID) {
                                nextVideoindex = i + 1;
                            }
                        }
                        let nextVideoKalturaID = playListData[nextVideoindex].kaltura_id;
                        if (nextVideoKalturaID) {
                            navigate(`/content-watch/${nextVideoKalturaID}-${playlistId}/${playlistId}`);
                        }
                    }
                }
            } else {
                setVideoEnd(true);
                setCancelled(false);
            }
        })
        //Pause Event - GA & LB Entry
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.PAUSE, (event) => {
            props.SetAutoPlay(false);
            let gaModel = {
                event: 'video', eventCategory: 'Video',
                eventAction: 'Pause',
                videoLength: kalturaPlayer.duration,
                videoId: ContentIdModifier(content_id),
                videoCurrentTime: kalturaPlayer.currentTime,
                videoVolume: kalturaPlayer.volume * 100
            };
            contentService.PushKDPEvents(gaModel);
            let obj = {
                log_action: "Video Paused",
                content_id: ContentIdModifier(content_id),
                country_code: countryData ? countryData.country_code : '',
                okta_id: hcp_id,
                log_event_label: document.baseURI,
                utm_source: GetUTMParameters('utm_source'),
                utm_medium: GetUTMParameters('utm_medium'),
                utm_campaign: GetUTMParameters('utm_campaign'),
                log_event_date_time: getDateTime()
            };
            try {
                contentService.UpdateLogViews(obj);
            } catch (err) { }
        })
        //Open Full Screen - GA & LB Entry
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ENTER_FULLSCREEN, (event) => {
            let gaModel = {
                event: 'video',
                eventCategory: 'Video',
                eventAction: 'Open Full Screen',
                videoLength: kalturaPlayer.duration,
                videoId: ContentIdModifier(content_id),
                videoCurrentTime: kalturaPlayer.currentTime,
                videoVolume: kalturaPlayer.volume * 100
            };
            contentService.PushKDPEvents(gaModel);
            let obj = {
                log_action: "openFullScreen",
                content_id: ContentIdModifier(content_id),
                country_code: countryData ? countryData.country_code : '',
                okta_id: hcp_id,
                log_event_label: document.baseURI,
                utm_source: GetUTMParameters('utm_source'),
                utm_medium: GetUTMParameters('utm_medium'),
                utm_campaign: GetUTMParameters('utm_campaign'),
                log_event_date_time: getDateTime()
            };
            try {
                contentService.UpdateLogViews(obj);
            } catch (err) { }
        })

        //Close Full Screen - LB & GA Entry
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.EXIT_FULLSCREEN, (event) => {
            let gaModel = {
                event: 'video', eventCategory: 'Video',
                eventAction: 'Close Full Screen',
                videoLength: kalturaPlayer.duration,
                videoId: ContentIdModifier(content_id),
                videoCurrentTime: kalturaPlayer.currentTime,
                videoVolume: kalturaPlayer.volume * 100
            };
            contentService.PushKDPEvents(gaModel);
            let obj = {
                log_action: "closeFullScreen",
                content_id: ContentIdModifier(content_id),
                country_code: countryData ? countryData.country_code : '',
                okta_id: hcp_id,
                log_event_label: document.baseURI,
                utm_source: GetUTMParameters('utm_source'),
                utm_medium: GetUTMParameters('utm_medium'),
                utm_campaign: GetUTMParameters('utm_campaign'),
                log_event_date_time: getDateTime()
            };
            try {
                contentService.UpdateLogViews(obj);
            } catch (err) { }
        })

        //Mute & Unmute - GA & LB Entry
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.MUTE_CHANGE, (event) => {
            if (event.payload.mute) {
                let gaModel = {
                    event: 'video', eventCategory: 'Video',
                    eventAction: 'Video Muted',
                    videoId: ContentIdModifier(content_id)
                }
                contentService.PushKDPEvents(gaModel);
                let obj = {
                    log_action: "mute",
                    content_id: ContentIdModifier(content_id),
                    country_code: countryData ? countryData.country_code : '',
                    okta_id: hcp_id,
                    log_event_label: document.baseURI,
                    utm_source: GetUTMParameters('utm_source'),
                    utm_medium: GetUTMParameters('utm_medium'),
                    utm_campaign: GetUTMParameters('utm_campaign'),
                    log_event_date_time: getDateTime()
                };
                try {
                    contentService.UpdateLogViews(obj);
                } catch (err) { }
            } else {
                let gaModel = {
                    event: 'video',
                    eventCategory: 'Video',
                    eventAction: 'Video Un-Muted',
                    videoId: ContentIdModifier(content_id),
                };
                contentService.PushKDPEvents(gaModel);
                let obj = {
                    log_action: "unmute",
                    ccontent_id: ContentIdModifier(content_id),
                    country_code: countryData ? countryData.country_code : '',
                    okta_id: hcp_id,
                    log_event_label: document.baseURI,
                    utm_source: GetUTMParameters('utm_source'),
                    utm_medium: GetUTMParameters('utm_medium'),
                    utm_campaign: GetUTMParameters('utm_campaign'),
                    log_event_date_time: getDateTime()
                };
                try {
                    contentService.UpdateLogViews(obj);
                } catch (err) { }
            }
        })

        //MEDIA & PLAYER ERROR - GA Entry
        kalturaPlayer.addEventListener(kalturaPlayer.Event.Core.ERROR, event => {
            //event.payload.category == 3 - MEDIA ERROR 
            //event.payload.category == 7 - PLAYER ERROR 
            let gaModel = {
                event: 'video',
                eventCategory: 'Video',
                eventAction: 'Kaltura Error',
                videoId: ContentIdModifier(content_id)
            }
            contentService.PushKDPEvents(gaModel);
        });

        setSharableLink(document.baseURI);
        let countryName = localStorage.getItem('country');
        let countryDetails = getCountryDetailsforShare(countryName);
        let newSharableLink = process.env.REACT_APP_PASSPORT_BASE_URL + `${countryDetails.locale}/signin/?url=` + document.baseURI;
        setSharableLink(newSharableLink)
        props.setHcpId(hcp_id);
        // Playlist AutoPlay
        if (kaltura_id.indexOf('-') > -1) {
            let urlParams = kaltura_id.split('-');
            let urlKalturaID = urlParams[0];
            if (playListData?.length) {
                if (playListData[0].kaltura_id !== urlKalturaID) {
                    props.SetAutoPlay(true);
                }
            }
        }
        kalturaPlayer.loadMedia({ entryId: kalturaId }, { startTime: mediaFrom }).then((success) => {
            kalturaPlayerRef.current = kalturaPlayer;
            SetPlayerId('player-content-widget');
            props.SetMainPlayerId('player-content-widget');
            SetWidgetCallback(true);
        }).catch((error) => {

        });
    }

    const WidgetTrigger = (mediaFrom, kalturaId) => {
        //Implement V7 Widget Here and Remove V2 Widget
        const element = document.getElementById('player-content-widget');
        if (element) {
            element.innerHTML = '';
        }
        KDBBindindgAsyncCal();
        return; //remove below mention code from this function
        // let widgetHeight = '400px';
        // if (window.innerWidth <= 520) {
        //     widgetHeight = '200px';
        // }
        // let ks_player_localisation = {
        //     'mwe-embedplayer-timed_text': 'Closed Captions',
        //     'mwe-timedtext-no-subtitles': 'No Subtitles',
        //     'mwe-embedplayer-player_fullscreen': 'Full screen',
        //     'mwe-embedplayer-player_closefullscreen': 'Exit full screen',
        //     'mwe-embedplayer-share': 'Share',
        //     'mwe-embedplayer-volume-mute': `${props.translations['Mute'] ? props.translations['Mute'] : 'Mute'}`,
        //     'mwe-embedplayer-volume-unmute': `${props.translations['Unmute'] ? props.translations['Unmute'] : 'Unmute'}`,
        //     'mwe-embedplayer-play_clip': 'Play clip',
        //     'mwe-embedplayer-pause_clip': 'Pause clip',
        //     'mwe-embedplayer-replay': 'Replay',
        // };
        // let flashvars = { // flashvars allows you to set runtime uiVar configuration overrides.
        //     'autoMute': false,
        //     "titleLabel": {
        //         "plugin": false,
        //         "align": "left",
        //         "text": "{mediaProxy.entry.name}"
        //     },
        //     'autoPlay': mediaFrom > 0 ? true : false,
        //     "EmbedPlayer.WebKitPlaysInline": true,
        //     'strings': ks_player_localisation,
        //     'controlBarContainer': {
        //         'hover': true
        //     },
        //     "share": {
        //         "plug": false,
        //         "plugin": false
        //     },
        //     "mediaProxy.mediaPlayFrom": mediaFrom,
        // };

        // let widgetSetting = {
        //     'targetId': 'player-content-widget',
        //     'wid': '_1759891',
        //     'uiconf_id': '48704833',
        //     'entry_id': kalturaId,// data.kaltura_id,
        //     'width': '100%',
        //     'height': widgetHeight,
        //     'flashvars': flashvars,
        //     'params': { 'wmode': 'transparent' }
        // };
        // widgetSetting.readyCallback = function (playerId) {
        //     // SetPlayerId(playerId);
        //     // props.SetMainPlayerId(playerId);
        //     function isEmpty(obj1) {
        //         return Object.keys(obj1).length === 0;
        //     }
        //     let hcp_id = isEmpty(sessionState.sessionValue) ? 'NA' : sessionState.sessionValue.payload.decodedToken?.id;
        //     // SetWidgetCallback(true);
        // };
        // if (window.kWidget) {
        //     window.kWidget.embed(widgetSetting);
        // }

    }

    const shuffle = (array) => {
        const newArray = [...array];
        newArray.reverse().forEach((item, index) => {
            const j = Math.floor(Math.random() * (index + 1));
            [newArray[index], newArray[j]] = [newArray[j], newArray[index]];
        });
        return newArray;
    };
    const KDBBindindgAsyncCal = async () => {
        let mediaFrom = 0;
        let kalturaId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
        if (kalturaId.indexOf('-') > -1) {
            let urlParams = kalturaId.split('-');
            kalturaId = urlParams[0];
            let playlistId = urlParams[1];//adsadf
            GetSetPlaylistData(playlistId);
            mediaFrom = 0.01;
        } else {
            SetIsPlaylist(false)
        }

        SetKalturaId(kalturaId);
        let data;
        data = await getContentData(kalturaId, ContentIdModifier(content_id), navigate);
        if (content_id.includes('PL')) {
            CheckIfVideoIsEligibleToWatch(data, true);
        } else {
            CheckIfVideoIsEligibleToWatch(data, false);
        }
        if (!data) {
            return;
        }
        if (kalturaId === props.play.kalturaID) {
            mediaFrom = props.play.startTime;
        }
        if (props.positionMainPlayer) {
            if (props.positionMainPlayer > 0) {
                mediaFrom = props.positionMainPlayer;
            }
        }
        let countryName = '';
        if (selectorValue.sessionValue.payload) {
            if (Array.isArray(selectorValue.sessionValue.payload.affiliate)) {
                if (selectorValue.sessionValue.payload.affiliate.length > 0) {
                    if (selectorValue.sessionValue.payload.affiliate[0].country_name) {
                        countryName = selectorValue.sessionValue.payload.affiliate[0].country_name
                    }
                }

            }
        }

        if (localStorage.getItem('country')) {
            countryName = localStorage.getItem('country')
        }
        let countryData = await getAffiliateId(countryName);
        function isEmpty(obj1) {
            return Object.keys(obj1).length === 0;
        }
        let hcp_id_01 = isEmpty(sessionState.sessionValue) ? 'NA' : sessionState.sessionValue.payload.decodedToken.id;
        setplayListContentId(data?.content_id)
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        let kalturaId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
        let playlistId = 0;
        let mediaFrom = 0;
        if (kalturaId.indexOf('-') > -1) {
            let urlParams = kalturaId.split('-');
            kalturaId = urlParams[0];
            playlistId = urlParams[1];
            mediaFrom = 0.01;
            GetSetPlaylistData(playlistId);
        } else {
            SetIsPlaylist(false)
        }
        let timeCurrent = 0;
        let contID = 0;
        /** Check if video has already been playing on minimize view */
        if (kalturaId === props.play.kalturaID) {
            mediaFrom = props.play.startTime;
        }
        if (props.positionMainPlayer) {
            if (props.positionMainPlayer > 0) {
                if (props.customPathHook.includes('content-watch') && props.lastContentWatchURL.includes('content-watch') && props.customPathHook != props.lastContentWatchURL) {
                    mediaFrom = 0;
                } else {
                    mediaFrom = props.positionMainPlayer;
                }

            }
        }
        function isEmpty(obj1) {
            return Object.keys(obj1).length === 0;
        }
        SetKalturaId(kalturaId);
        async function InitializePlayer(mediaFrom, kalturaId) {
            let data;
            data = await getContentData(kalturaId, ContentIdModifier(content_id), navigate);
            if (content_id.includes('PL')) {
                CheckIfVideoIsEligibleToWatch(data, true);
            } else {
                CheckIfVideoIsEligibleToWatch(data, false);
            }
            if (!data) {
                return;
            }
            if (data.duration) {
                SetDuration(data.duration)
            }
            if (data?.quiz_credit?.is_mandatory) {
                SetCheckQuizMandatory(true);
            } else {
                SetCheckQuizMandatory(false);
            }
            if (Array.isArray(data?.content_libraries)) {
                if (data?.content_libraries.length > 0) {
                    if (!data.content_libraries.includes(props.selectedLibraryid)) {
                        contentService.UpdateLibrary(userId, data?.content_libraries[0]);
                        SetUpNextUpdateCheck(true);

                        let oldSessionObject = Object.assign({}, selectorValue.sessionValue.payload);
                        if (!oldSessionObject?.simulationCheck) {
                            props.SetselectedLibrary(data?.content_libraries[0]);
                            props.SetselectedLibraryid(data?.content_libraries[0]);
                        }
                        if (oldSessionObject) {
                            if (oldSessionObject.user) {
                                if (oldSessionObject.user.active_library != data?.content_libraries[0] && !oldSessionObject?.simulationCheck) {
                                    oldSessionObject.user.active_library = data?.content_libraries[0];
                                    (new SessionService()).UpdateSessionObject(dispatch, oldSessionObject)
                                }
                            }
                        }
                    }



                }
            }
            SetCreatedBy(data.created_by);
            props.SetContentId(ContentIdModifier(content_id));
            let playObject = Object.assign({}, props.play);
            playObject.kalturaID = kalturaId;
            props.Setplay(playObject);
            let recordingDate = (new Date(data.created_at)).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
            SetRecordingInstant(recordingDate)
            let speakerlist = await contentService.GetSpeakersById(data.speakers_id ? data.speakers_id : []);
            let dateFrom = new Date(data.created_at);
            let dateTo = new Date();
            let monthDiff = (dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())));
            SetactiveContentId(data.content_id)
            setContentID(data.content_id);
            SetCategory1Ids(data.category_1_id);
            contID = data.content_id;
            setContentType(data.content_type);
            let dynamicTitle = '';
            let dynamicDesc = '';
            let isDynamicData = false;
            if (data?.multilingual_content?.Switzerland?.German?.checked || data?.multilingual_content?.Switzerland?.French?.checked ||
                data?.multilingual_content?.Belgium?.Dutch?.checked || data?.multilingual_content?.Belgium?.French?.checked) {
                isDynamicData = true;
            }
            let country = localStorage.getItem('country') ?? '';
            let localeName = localStorage.getItem('lang') ? localStorage.getItem('lang') : '';
            if (isDynamicData && localeName && (country == 'Switzerland' || country == 'Belgium')) {
                if (country == 'Switzerland') {
                    if (localeName == 'de-CH') {
                        dynamicTitle = data?.multilingual_content?.Switzerland?.German?.title ?? '';
                        dynamicDesc = data?.multilingual_content?.Switzerland?.German?.description ?? '';
                    } else if (localeName == 'fr_CH') {
                        dynamicTitle = data?.multilingual_content?.Switzerland?.French?.title ?? '';
                        dynamicDesc = data?.multilingual_content?.Switzerland?.French?.description ?? '';
                    }
                } else {
                    if (localeName == 'nl-BE') {
                        dynamicTitle = data?.multilingual_content?.Belgium?.Dutch?.title ?? '';
                        dynamicDesc = data?.multilingual_content?.Belgium?.Dutch?.description ?? '';
                    } else if (localeName == 'fr-BE') {
                        dynamicTitle = data?.multilingual_content?.Belgium?.French?.title ?? '';
                        dynamicDesc = data?.multilingual_content?.Belgium?.French?.description ?? '';
                    }
                }
            } else {
                dynamicTitle = data.title ?? '';
                dynamicDesc = data?.description ?? '';
            }
            const description = dynamicDesc?.replaceAll('1.5rem', '14px');
            SetvideoDescription(description);
            SetSpeakers(speakerlist);
            SetContentTitle(dynamicTitle);
            contentService.PushVideoDataGA(data.description)
            setVideoview(true);
            Settags(data.content_tags);
            let listMat = [];
            let material = Array.isArray(data.materials_uploaded) ? data.materials_uploaded : JSON.parse(data.materials_uploaded);

            if (material) {
                for (let i = 0; i < material.length; i++) {
                    if (material[i].file != '' || material[i].linkMask != '') {
                        if (material[i].file == '' && material[i].linkMask != '') {
                            material[i].filename = material[i].linkMask
                        }
                        listMat.push(material[i]);
                    }
                }
            }

            SetMaterials(listMat);
            setSharable(data.sharable_content);
            SetCid(data.content_id);

            if (data.product_characteristic.length !== 0) {
                for (let i = 0; i < data.product_characteristic.length; i++) {
                    let brandArr = await contentService.GetBrandName(data.product_characteristic[i].brand_id);
                    data.product_characteristic[i].brand_name = brandArr ? brandArr.category_1_name : '';
                }
                SetProductCharacteristics(data.product_characteristic);
            }

            if (Array.isArray(data.materials_uploaded)) {
                if (data.materials_uploaded.length > 0) {
                    if (data.materials_uploaded[0].filethumb != undefined) {
                        let newArr = {};
                        let newArr2 = {};

                        for (let i = 0; i < data.materials_uploaded.length; i++) {
                            contentService.GetS3File(data.materials_uploaded[i].filethumb).then(data => {
                                newArr['image' + i] = data
                                setthumbnail(...thumbnail, newArr);
                            }).catch(err => { });
                            contentService.GetS3File(data.materials_uploaded[i].file).then(data => {
                                newArr2['image' + i] = data
                                setactualfile(...actualfile, newArr2);
                            }).catch(err => { });
                        }
                    }
                }
            }
            setWatchData(data.content_id)
            if (!isPlaylist && data.quiz_credit !== undefined) {
                if (data.quiz_credit && data.quiz_credit.quiz_id) {
                    SetquizId(data.quiz_credit.quiz_id);
                    SetIsMandatory(data.quiz_credit.is_mandatory);
                } else {
                    SetquizId('');
                    SetIsMandatory(false);
                }

            }
            let countryName = '';
            let countryData = {}
            if (selectorValue.sessionValue.payload) {
                if (Array.isArray(selectorValue.sessionValue.payload.affiliate)) {
                    if (selectorValue.sessionValue.payload.affiliate.length > 0) {
                        countryName = selectorValue.sessionValue.payload.affiliate[0].country_name

                        if (localStorage.getItem('country')) {
                            countryName = localStorage.getItem('country')
                        }
                        countryData = await getAffiliateId(countryName, dispatch, affiliateSelector, setAffiliate);
                        if (countryData) {
                            SetAffiliateId(countryData.country_code);
                            SetshowMoreactive(countryData.show_more);
                            SetIsLikeActive(countryData.is_like);
                            SetcountryUpNext(countryData.upnext);
                        }
                    }
                }
            }
            setHCPValue(localStorage.getItem('HCP'));
            SetMonths(dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())));


            const date1 = dateFrom;
            const date2 = dateTo;
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            let aDay = 24 * 60 * 60 * 1000 * (diffTime / (1000 * 60 * 60 * 24));
            let monthsAgo = time_ago(new Date(Date.now() - aDay));
            if (monthsAgo.includes('months ago')) {
                if (props.translations['X Months Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Months Ago'] ? props.translations['X Months Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('month ago') || monthsAgo.includes('Month ago')) {
                if (props.translations['1 month Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['1 month Ago'] ? props.translations['1 month Ago'] : monthsAgo}`;
                }
            } else if (monthsAgo.includes('weeks ago')) {
                if (props.translations['X Weeks Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Weeks Ago'] ? props.translations['X Weeks Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('week ago')) {
                if (props.translations['X Week Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Week Ago'] ? props.translations['X Week Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('days ago')) {
                if (props.translations['X Days Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Days Ago'] ? props.translations['X Days Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('minutes ago')) {
                if (props.translations['X Minutes Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Minutes Ago'] ? props.translations['X Minutes Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('seconds ago')) {
                if (props.translations['X Seconds Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Seconds Ago'] ? props.translations['X Seconds Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('hours ago')) {
                if (props.translations['X Hours Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Hours Ago'] ? props.translations['X Hours Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('years ago')) {
                if (props.translations['X Years Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Years Ago'] ? props.translations['X Years Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('year ago')) {
                if (props.translations['X Year Ago']) {
                    let tempMon = monthsAgo.split(' ').slice(0, 1);
                    monthsAgo = `${props.translations['X Year Ago'] ? props.translations['X Year Ago'] : monthsAgo}`;
                    monthsAgo = monthsAgo.replaceAll('X ', `${tempMon} `);
                }
            } else if (monthsAgo.includes('1 day ago')) {
                if (props.translations['1 Day Ago']) {
                    monthsAgo = `${props.translations['1 Day Ago'] ? props.translations['1 Day Ago'] : monthsAgo}`;
                }
            } else if (monthsAgo.includes('Last day')) {
                if (props.translations['Last day']) {
                    monthsAgo = `${props.translations['Last day'] ? props.translations['Last day'] : monthsAgo}`;
                }
            } else if (monthsAgo.includes('Last week')) {
                if (props.translations['Last week']) {
                    monthsAgo = `${props.translations['Last week'] ? props.translations['Last week'] : monthsAgo}`;
                }
            } else if (monthsAgo.includes('Last year')) {
                if (props.translations['Last Year']) {
                    monthsAgo = `${props.translations['Last Year'] ? props.translations['Last Year'] : monthsAgo}`;
                }
            } else if (monthsAgo.includes('Last month')) {
                if (props.translations['Last Month']) {
                    monthsAgo = `${props.translations['Last Month'] ? props.translations['Last Month'] : monthsAgo}`;
                }
            }
            SetMonths(monthsAgo);
            contentService.GetVideoStats(data.content_id).then((data) => {
                SetLikes(data.totallikes);
                SettotalViews(data.totalviews);
            }).catch((err) => { });
            let hcp_id_01 = isEmpty(sessionState.sessionValue) ? 'NA' : sessionState.sessionValue.payload.decodedToken.id;
            let lastPlaylistValue;
            SetPlaylistVideos(prevValue => {
                lastPlaylistValue = prevValue;
                return prevValue;
            })
            hcp_id_01 !== 'NA' && WidgetTriggerV7(mediaFrom, countryData, data, hcp_id_01, playerIdState, data.content_id, kalturaId, lastPlaylistValue)
        }
        InitializePlayer(mediaFrom, kalturaId); //Remove this V2 code and Implement V7ss

        /** Set video player state when player is closed */
        return () => {
            props.Setplay(prev => ({ ...prev, state: true, startTime: timeCurrent, kalturaID: kalturaId }));
            let userID = userId;

            if (selectorValue) {
                if (selectorValue.sessionValue.payload) {
                    if (selectorValue.sessionValue.payload.user) {
                        if (selectorValue.sessionValue.payload.user) {
                            userID = selectorValue.sessionValue.payload.user.id
                        }
                    }
                }
            }
            let insertObj = {
                user_id: userID,
                content_id: contID,
                watched_percent: viewwatchpercentage,
                watched_on: new Date(),
            };
        }
    }, [kaltura_id, userId, content_id, finalAffiliateIdData, finalContentData]);



    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: removeHtmlTags
    @Parameters: str: String
    @Return: String
    @statement: This function removes the html tags from the description section of the content.
    @API-return: The user sometimes add the html to the description. This happens when the user copies the description from another document instead of typing it. To remove the excess tages this function parses the string and removes all the HTML tags so that the description is crisp and clear.
    */
    function removeHtmlTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: OrderByUpdatedAt
    @Parameters: list: String
    @Return: String[]
    @statement: This function sorts the content list based on the order in which the content was created ot updated.
    @API-return: The content in the related contents should be visible to the user based on the latest created or updated date of the content.
    */
    const OrderByUpdatedAt = (list) => {
        if (Array.isArray(list) && list?.length > 0) {
            const newArray = list.sort(function (first, second) {
                let a = new Date(first.updated_at);
                let b = new Date(second.updated_at);

                if (a < b) {
                    return 1;
                } else if (a > b) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return newArray
        } else {
            return list;
        }
    }
    const MapPlaylistKalturaIds = async (playlistArray) => {

        let list = [];
        let dict = {};
        if (Array.isArray(playlistArray)) {
            for (let i = 0; i < playlistArray.length; i++) {
                if (Array.isArray(playlistArray[i].content_ids)) {
                    if (playlistArray[i].content_ids.length > 0) {
                        // list.push(playlistArray[i].content_ids[0]);
                        dict[playlistArray[i].content_ids[0]] = playlistArray[i]
                    }
                }
            }
        }
        await contentService.GetPlayListVideos(Object.keys(dict)).then((data2) => {
            if (Array.isArray(data2)) {

                for (let i = 0; i < data2.length; i++) {
                    let playlist = dict[data2[i].content_id];
                    playlist['kaltura_id'] = data2[i].kaltura_id;
                    list.push(playlist);
                }
            }
            return list;

        }).catch((err) => { })
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: GetAffiliateCodesArrayWithSessionState
    @Parameters: N/A
    @Return: N/A
    @statement: This functions sets the country codes based on user access levels into a list
    @API-return: The content should be visible for only those users whose access levels on the countries matched with the content's country code.
    */
    const GetAffiliateCodesArrayWithSessionState = () => {
        let list = []
        if (selectorValue) {
            if (selectorValue.sessionValue.payload) {
                if (Array.isArray(selectorValue.sessionValue.payload.affiliate)) {
                    let affiliates = selectorValue.sessionValue.payload.affiliate
                    for (let i = 0; i < affiliates.length; i++) {
                        if (affiliates[i]) {
                            list.push(affiliates[i].country_code);
                        }
                    }
                }
            }
        }
        return list;
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: getUpNextVideo
    @Parameters: contentIdUpNext: String, playlistIdUpnext String
    @Return: N/A
    @statement: This function fetches the next content details that should be fetched after the current content end's based on the meta data of the current content id / playlist id to return the relevant content that matched the meta data of the current content id or playlist id
    @API-return: When the user opens the content and completes watching the video till the end and 5 second pop up should show describing the next content that would auto play and redirect to the new content after the 5 second timer ends and a cancel button then will stop the autoplay and redirect to new content and remain on the current content that has ended.
    */
    const getUpNextVideo = (contentIdUpNext, playlistIdUpnext) => {
        SetRelatedVideosFetchStatus(false);
        let current_content_id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        if (selectorValue.sessionValue.payload) {
            let contentId = current_content_id.includes('PL') ? contentIdUpNext : current_content_id;
            let userId = selectorValue.sessionValue.payload?.user?.id;
            let specialty = selectorValue.sessionValue.payload?.decodedToken?.specialty ? selectorValue.sessionValue.payload?.decodedToken?.specialty : selectorValue.sessionValue.payload?.user?.specialty ? selectorValue.sessionValue.payload?.user?.specialty : "";
            let profession = selectorValue.sessionValue.payload?.decodedToken?.profession ? selectorValue.sessionValue.payload?.decodedToken?.profession : selectorValue.sessionValue.payload?.user?.profession ? selectorValue.sessionValue.payload?.user?.profession : "";
            let countryCode = selectorValue.sessionValue.payload?.decodedToken?.countryCode ? selectorValue.sessionValue.payload?.decodedToken?.countryCode : GetAffiliateCodesArrayWithSessionState();
            if (countryCode.length == 0) {
                return;
            }
            let taId = [];
            let cat1Id = [];
            let cat2Id = [];
            let category_id = selectorValue.sessionValue.payload?.affiliate[0]?.therapeutic_area_ids;

            contentService.GetUpNextVideos(contentId, specialty, profession, countryCode, '', userId, taId, cat1Id, cat2Id, props.selectedLibraryid, category_id).then(async (data) => {
                if (data.length > 0) {
                    data.filter((data)=> CheckIfVideoIsEligibleToWatch(data, true))
                    setUpNextVideo(data[0].kaltura_id)
                    setUpNextVideoTitle(data[0].title);
                    SetUpNextContentId(data[0].content_id);
                    taId.push(data[0].therapeutic_area_id);
                    await contentService.GetUpnextPlaylist(contentId, specialty, profession, countryCode, '', userId, taId, cat1Id, cat2Id, props.selectedLibraryid, playlistIdUpnext).then(async (data2) => {

                        let list = [];
                        let dict = {};
                        let playlistArray = data2
                        if (Array.isArray(playlistArray)) {
                            for (let i = 0; i < playlistArray.length; i++) {
                                if (Array.isArray(playlistArray[i].content_ids)) {
                                    if (playlistArray[i].content_ids.length > 0) {
                                        if (playlistIdUpnext !== playlistArray[i].playlist_id)
                                            dict[playlistArray[i].content_ids[0]] = playlistArray[i]
                                    }
                                }
                            }
                        }
                        await contentService.GetPlayListVideos(Object.keys(dict)).then(async (data21) => {
                            if (Array.isArray(data21)) {

                                for (let i = 0; i < data21.length; i++) {
                                    let playlist = dict[data21[i].content_id];
                                    playlist['kaltura_id'] = data21[i].kaltura_id;
                                    list.push(playlist);
                                }
                            }
                            let allData = OrderByUpdatedAt(data.concat(list));
                            let relatedVideos = await GetVideosWithSpeakersName(allData);
                            SetRelatedVideos(relatedVideos);
                            SetRelatedVideosFetchStatus(true);
                        }).catch((err) => { SetRelatedVideos([]); SetRelatedVideosFetchStatus(true) })
                    }).catch((err) => { SetRelatedVideos([]); SetRelatedVideosFetchStatus(true) })

                } else {
                    SetRelatedVideos([]);
                    SetRelatedVideosFetchStatus(true);
                }

            }).catch((err) => { SetRelatedVideos([]); SetRelatedVideosFetchStatus(true) })

        }
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: time_ago
    @Parameters: time: String
    @Return: String
    @statement: This function calulates the time elapsed since the content has been uploaded on the platform
    @API-return: When the user opens the content, the time that has been passed since upoading the content on platform should be visible, so that the user can validate the freshness of the content.
    */
    function time_ago(time) {

        switch (typeof time) {
            case 'number':
                break;
            case 'string':
                time = +new Date(time);
                break;
            case 'object':
                if (time.constructor === Date) time = time.getTime();
                break;
            default:
                time = +new Date();
        }
        let time_formats = [
            [60, 'seconds', 1], // 60
            [120, '1 minute ago', '1 minute from now'], // 60*2
            [3600, 'minutes', 60], // 60*60, 60
            [7200, '1 hour ago', '1 hour from now'], // 60*60*2
            [86400, 'hours', 3600], // 60*60*24, 60*60
            [172800, '1 day ago', 'Tomorrow'], // 60*60*24*2
            [604800, 'days', 86400], // 60*60*24*7, 60*60*24
            [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
            [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
            [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
            [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
            [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
            [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
            [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
            [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
        ];
        let seconds = (+new Date() - time) / 1000,
            token = 'ago',
            list_choice = 1;

        if (seconds == 0) {
            return 'Just now'
        }
        if (seconds < 0) {
            seconds = Math.abs(seconds);
            token = 'from now';
            list_choice = 2;
        }
        let i = 0,
            format;
        while (format = time_formats[i++])
            if (seconds < format[0]) {
                if (typeof format[2] == 'string')
                    return format[list_choice];
                else
                    return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
            }
        return time;
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: OpenPDF
    @Parameters: newUrl: String
    @Return: N/A
    @statement: This function fetches the s3 file based on the newURL string and returns a downloadable pdf file.
    @API-return: When the user clicks on any of the item in additional resources it should download the file in the local environment of the user so that the user can refer that via their local machine.
    */
    function OpenPDF(newUrl) {
        let exte = newUrl.split(".");
        contentService.GetS3FileLink(newUrl).then(data => {
            let a = document.createElement("a"); //Create <a>
            a.href = data; //Image Base64 Goes here
            a.target = "_blank";
            a.download = "resource." + exte[exte.length - 1]; //File name Here
            a.click();
        }).catch(err => { });
    }

    function getCommonSpeakers(str1) {
        let finalString = 'N/A';
        let str = [];
        for (let i = 0; i < str1.length; i++) {
            str.push(str1[i].name);
        }
        let i = 0;
        if (Array.isArray(str) && str.length) {
            finalString = '';
            for (i = 0; i < str.length - 1; i++) {
                finalString += str[i] + ", ";
            }
            finalString += str[i];
        }
        return finalString;
    }
    React.useEffect(() => {
    }, [thumbnail, actualfile]);

    React.useEffect(() => {
        if (userId) {
            getFavoriteFlag(playListContentId == '' ? ContentIdModifier(content_id) : playListContentId, userId)
            getcontentLikeFlag(playListContentId == '' ? ContentIdModifier(content_id) : playListContentId, userId);
            if (content_id && playerIdState.length > 0 && userId.length > 0 && playListContentId.length > 0 && selectorValue?.sessionValue?.payload) {
                getUpNextVideo(playListContentId == '' ? ContentIdModifier(content_id) : playListContentId, playlistID);
            }

        }


    }, [content_id, playerIdState, userId, playListContentId, selectorValue.sessionValue.payload, props.selectedLibraryid, duration, playlistID, props.selectorValueSession]);

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: getcontentLikeFlag
    @Parameters: currentContentID: String, id: String
    @Return: Boolean
    @statement: This function checks if the content is liked by the current user or not
    @API-return: When the user watches the content, the like button should be coloured either Red or Grey based on if the user has liked the content or not.
    */
    async function getcontentLikeFlag(currentContentID, id) {
        let user_id = id;
        if (sessionState) {
            if (sessionState.sessionValue.payload) {
                if (sessionState.sessionValue.payload.user) {
                    if (sessionState.sessionValue.payload.user) {
                        user_id = sessionState.sessionValue.payload.user.id
                    }
                }
            }
        }
        let resData;
        let resData2 = await contentService.GetAllContentAudit({ content_id: currentContentID });
        resData = resData2.data;
        let latestAuditRecordData = await contentService.getLatestContentAudit({ content_id: currentContentID }, { content_id: currentContentID, user_id: user_id });
        let latestAuditRecord = latestAuditRecordData.data;
        if (latestAuditRecord) {
            for (let i = 0; i < latestAuditRecord.length; i++) {
                if(latestAuditRecord[0].watch_percentage < 100){
                    let checkRecord = latestAuditRecord[0];
                    let setTimeValue = contentService.GetTimeStamp(checkRecord, duration);
                    if (setTimeValue != 0) {
                        if (kalturaPlayerRef.current) {
                            if(checkQuizMandatory === false){
                                props.SetPositionMainPlayer(setTimeValue);
                                kalturaPlayerRef.current.currentTime = setTimeValue;
                            } else {
                                props.SetPositionMainPlayer(0);
                                kalturaPlayerRef.current.currentTime = 0;
                            }
                        }
                    }
                }
            }
        }
        let like = 1
        let count = 0;
        let user_ids = [];
        let content_ids = [];
        for (let i = 0; i < resData.length; i++) {
            if (resData[i].user_id === user_id && currentContentID === resData[i].content_id && resData[i].content_liked) {
                like = 0
                setLikeImg('likeRed.svg');
            }
            if (currentContentID === resData[i].content_id) {
                if (!user_ids.includes(resData[i].user_id)) {
                    count = count + 1;
                    user_ids.push(resData[i].user_id)
                    content_ids.push(resData[i].content_id);
                }
            }

        }
        setviewCount(count)
        if (like === 1) {
            setLikeImg('like.svg');
        } else {
            setLikeImg('likeRed.svg');
        }
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: getFavoriteFlag
    @Parameters: currentContentID: String, id: String
    @Return: Boolean
    @statement: This function checks if the content is favourited by the current user or not
    @API-return: When the user watches the content, the favourite button should be coloured either Red or Grey based on if the user has favourited the content or not.
    */
    function getFavoriteFlag(currentContentID, id) {
        let user_id = id;
        if (props.selectorValueSession) {
            if (props.selectorValueSession.sessionValue.payload) {
                if (props.selectorValueSession.sessionValue.payload.user) {
                    if (props.selectorValueSession.sessionValue.payload.user) {
                        user_id = props.selectorValueSession.sessionValue.payload.user.id
                    }
                }
            }
        }
        contentService.GetAllFavoriteData(id).then((data) => {
            let favdata = data.data;
            let flag = 0;
            if (Array.isArray(favdata)) {
                if (favdata.length > 0) {
                    let newFilterData = favdata.filter((item) => {
                        let dbContentID = (contentType === 'playlist') ? item.playlist_id : item.content_id;
                        if (dbContentID === currentContentID) {
                            setFavID(item.user_stat_id); setFavImg('favoriteRed.svg'); flag = 1;
                            return true;
                        }

                    });
                    if (Array.isArray(newFilterData) && newFilterData.length > 0) {
                        return;
                    }
                    if (flag === 0) {
                        setFavImg('favorite.svg');
                        setFavID(0);
                        return;
                    }
                }
                setFavImg('favorite.svg');
                setFavID(0);
            }
            setFavImg('favorite.svg');
            setFavID(0);

        }).catch((err) => { });
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: handlerFav
    @Parameters: N/A
    @Return: N/A
    @statement: This function sets the colour and visiblity of the 'Favourite' button.
    @API-return: When the user clicks on the Favourite button, the content should show the Favourite button as 'Red' coloured and save the details in My Favourite section of the application. Same for the condition to Clicking the Red Favourite button show the Favourite button as 'Black/Grey' coloured and remove the details from My Favourite section.
    */
    const handlerFav = () => {

        const userID = userId;
        let modal = {
            user_id: userID,
            content_id: contentID,
            playlist_id: null,
            country_code: selectorValue.sessionValue.payload.affiliate[0].country_code
        }
        if (favImg === "favorite.svg") {
            contentService.PushFavouriteData(contentID, true);
            contentService.AddToFavorite(modal).then((data) => {

                let favdata = data.data;
                setFavID(favdata.user_stat_id);
                setFavImg('favoriteRed.svg');
            }).catch((err) => { });
        } else {
            contentService.PushFavouriteData(contentID, false);
            contentService.RemoveFromFavorite(favID).then((data) => {
                setFavImg('favorite.svg');
            }).catch((err) => { });
        }
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: handlerLike
    @Parameters: N/A
    @Return: N/A
    @statement: This function sets the colour and visiblity of the 'Like' button.
    @API-return: When the user clicks on the Like button, the content should show the Like button as 'Red' coloured indicating the content has been favoured by the user. Same for the condition to Clicking the Red Like button show the Like button as 'Black/Grey' coloured.
    */
    const handlerLike = () => {
        let sperkerId = [];
        if (speakers) {
            for (let i = 0; i < speakers.length; i++) {
                sperkerId.push(speakers[i].speaker_id);
            }
        }
        if (likeImg === "like.svg") {
            setLikeImg('likeRed.svg');
            contentService.PushLikeData(ContentIdModifier(content_id), true)
            contentService.SaveAction(ContentIdModifier(content_id), kalturaId, contentType, 'like', userId, sperkerId);
            let _likes = parseInt(likes);
            SetLikes(_likes + 1);
        } else {
            setLikeImg('like.svg');
            contentService.PushLikeData(ContentIdModifier(content_id), false)
            contentService.SaveAction(ContentIdModifier(content_id).toString(), kalturaId, contentType, 'unlike', userId, sperkerId);
            let _likes = parseInt(likes);
            SetLikes(_likes - 1);
        }
    }

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: handleCopyCode
    @Parameters: N/A
    @Return: String
    @statement: This functions returns the url of the content with the passport details attached to it.
    @API-return: When user clicks on the share button on content-watch.jsx page, it should render a popup which returns the hcp usable link to the content.
    */
    const handleCopyCode = () => {
        navigator.clipboard.writeText(sharableLink);
    }

    /** Carousel Next Arrow  */
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={RightArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        marginTop: '-4%',
                        fontSize: '36px',
                        marginRight: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                        display: 'inline-block',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel Previous Arrow  */
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={LeftArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        marginTop: '-4%',
                        fontSize: '36px',
                        marginLeft: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                        display: 'inline-block',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel settings  */
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: false,
                    variableWidth: true,
                    infinite: false,
                    arrows: true,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                    infinite: false,
                    arrows: true,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    variableWidth: true,
                    infinite: false,
                    arrows: true

                }
            }
        ]
    };

    /*
    Created BY: Shubham Yadav
    Created On: 15/05/2024
    @Function: handleSpeakerClick
    @Parameters: speakerInfo: String[]
    @Return: N/A
    @statement: This functions sets the speaker details mapping to local variables.
    @API-return: On Home Page when you click on speaker name, the speaker details should be shown in the pop-up
    */
    const handleSpeakerClick = (speakerInfo) => {
        SetSpeakerBio(speakerInfo.curriculum);
        SetSpeakerName(speakerInfo.name);
        contentService.GetImageFromURL(speakerInfo.avatar_attachment_id).then((data) => {
            SetSpeakerImg(data);
        }).catch((err) => { });
        SetSpeakerCVModal(true);
    }
    // LILLYPLAY-3571 Moving this useeffect Logic to a new component
    // React.useEffect(() => {
    //     let targetLinks = document.querySelectorAll('.content_watch_desc a');
    //     for (let i = 0; i < targetLinks.length; i++) {
    //         targetLinks[i].addEventListener('click', function (event) {
    //             event.preventDefault();
    //             let href = this.getAttribute('href');
    //             if (event.target.classList.contains('openNewWinDow')) {
    //                 SetRedirectLink(href);
    //                 SetRedirectWaringModal(true);
    //             } else {
    //                 window.open(href, '_blank')
    //             }
    //         });
    //     }
    // }, [videoDescription]);
    React.useEffect(() => {
        console.log('quiz',quizId)
    }, [countryUpNext, videoEnd, cancelled])
    const openTag = (tgs) => {
        navigate('/search/' + encodeURIComponent(tgs))
    }
    
    const cancelCurrentVideo = () => {
        console.log("document.baseURI", document.baseURI);
        // window.location.href = document.baseURI;
        props.SetPositionMainPlayer(0)
        setCancelled(true);
    }

  return (
        <Grid>
            <Breadcrumb disableLinkIndex={1} translations={props.translations} setFilter={props.setFilter} BreadcrumbData={breadcrumbDetails} />
            <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">Oops! This video can’t be fast forwarded because it has a mandatory quiz</Alert>
                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
                    </Grid>
                </Grid>
            </Modal>
            <Modal open={navigatePopup}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-body">
                        <button className='closeModal' onClick={()=> {window.location.href='/home'}}></button>
                        <div className="m20">
                            <p>{redirectModalMessage[localStorage.getItem('lang')]}</p>
                        </div>
                    </Grid>
                </Grid>
            </Modal>
            <Modal open={speakerCVModal} onClose={() => { SetSpeakerCVModal(false) }}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-body">
                        <button className='closeModal' onClick={() => SetSpeakerCVModal(false)}></button>
                        <div className="m20">
                            <div className="text-center">
                                <h3>{speakerName}</h3>
                                <div className="roundedImg">
                                    {speakerImg ? <img src={speakerImg} alt="speakerImg" /> : <img src={meetSpeakerIcon} alt="speakerIcon" />}
                                </div>
                            </div>
                            <h4>Curriculum vitae</h4>
                            <p>{speakerBio}</p>
                        </div>
                    </Grid>
                </Grid>
            </Modal>
            {/* 
            Changes by Shreya
            Changes for LILLYPLAY-3571
            <Modal open={redirectWaringModal} onClose={() => { SetRedirectWaringModal(false) }}>
                <Grid className='paper modalstyle redirectWarningModal'>
                    <Grid className="modal-body">
                        <button className='closeModal' onClick={() => SetRedirectWaringModal(false)}></button>
                        <div className="m20">
                            <div className="text-center">
                                <h3>You are now leaving {window.location.origin}</h3>
                                <p>The link you clicked on will take you to a site maintained by a third party, which is solely responsible for its content. Eli Lilly Company and its affiliates do not control, influence, or endorse this site, and the opinions, claims, or comments expressed on this site should not be attributed to Eli Lilly Company and its affiliates. Eli Lilly Company and its affiliates are not responsible for the  privacy policy of any third-party websites. We encourage you to read the privacy policy of every website you visit.</p>
                                <p>Click "Continue" to leave site or "Return to Lilly Play" to close this window and return to {window.location.origin}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="modal-footer">
                        <button className='modalBtn' onClick={() => { SetRedirectWaringModal(false) }}>Return to Lilly Play</button>
                        <button type="button" onClick={() => { window.open(redirectLink, '_blank'); SetRedirectWaringModal(false); }} className="modalBtn">Continue</button>
                    </Grid>
                </Grid>
            </Modal> */}
            <Modal open={openEmbedModal} onClose={() => { setEmbedOpenModal(false); SetcopyClicked(false); }}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        {props.translations['Sharable Link'] ? props.translations['Sharable Link'] : 'Sharable Link'}
                        <button className='closeModal' onClick={() => { setEmbedOpenModal(false); SetcopyClicked(false); }}></button>
                    </Grid>
                    <Grid className='modal-body' item lg={12} xs={12}>
                        <div className="word-break m20">
                            <Alert severity="warning"> {sharableLink}</Alert>
                        </div>
                        {copyClicked ? <Grid className="content_embed posAbs">
                            <img className='iconChild' src={codeCopied1} alt='Edit' />Link Copied
                        </Grid> : null}
                    </Grid>
                    <Grid className="modal-footer">
                        <button type="button" onClick={() => { setEmbedOpenModal(false); SetcopyClicked(false); }} className="modalBtn closeBtn">{props.translations['Close'] ? props.translations['Close'] : 'Close'}</button>
                        <button className='modalBtn' onClick={() => {
                            handleCopyCode();
                            (new ContentService()).CopyLinkButtonClick(ContentIdModifier(content_id));
                            SetcopyClicked(true);
                        }}> {props.translations['Copy Link'] ? props.translations['Copy Link'] : 'Copy Link'}  </button>
                    </Grid>
                </Grid>
            </Modal>
            { navigatePopup === false ?
            <Grid className='outer-wrapper'>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={7} md={8} sm={7}>
                        {videoview || true ?
                            <Grid className='content-playSec'>
                                <div id='player-content-widget' style={{ width: '100%', height: '400px' }}></div>
                                {relatedVideosFetchStatus && countryUpNext && upNextVideoTitle && videoEnd && cancelled === false && openModal === false ?
                                    <Grid className='up-next'>
                                        <UpNext title={upNextVideoTitle} kaltura_id={upNextVideo} stateChanger={setCancelled} content_id={upNextContentId} translations={props.translations} />
                                        <div style={{ color: "white" }}><a href onClick={() => cancelCurrentVideo()}>{props.translations['Cancel'] ? props.translations['Cancel'] : 'Cancel'}</a></div>
                                    </Grid> : null
                                    //  : null : null
                                }
                                {sessionState?.sessionValue?.payload?.affiliate[0]?.mini_player || !sessionState.sessionValue?.payload?.user.role_ids.includes(1) ?
                                    <img src={minatureIcon} onClick={() => navigate('/home')} className='minatureIcn' alt='icon' /> : null}
                            </Grid> :
                            <Grid className='text-center m20'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>}
                        <Hidden smUp>
                            <Grid container className='content-playlistSec'>
                                {kaltura_id.indexOf('-') > -1 && Array.isArray(_playlistVideos) ?
                                    <PlayList kalturaId={kalturaId}
                                        playlistID={playlistID} playlistTitle={playlistTitle}
                                        playlistVideos={_playlistVideos} isSeries={isSeries} imagearray={imagearray} />
                                    : null}
                            </Grid>
                        </Hidden>
                        <Grid container className='content-metaSec'>
                            <Grid item xs={4} lg={6}>
                                {contentType !== "" ? <Paper className={contentClassValue}>{contentType}</Paper> : ""}
                            </Grid>
                            <Grid item xs={8} lg={6}>
                                <ul className='userActionSec'>
                                    {isLikeActive ? isHCP === 'false' ?
                                        <li><img src={require('../../public/img/' + likeImg)} alt='like_icon' className="like" onClick={handlerLike} />{likes ? parseInt(likes) : ""} {props.translations['Likes'] ? props.translations['Likes'] : 'Likes'}</li>
                                        :
                                        <li><img src={require('../../public/img/' + likeImg)} alt='like_icon' className="like" onClick={handlerLike} />{props.translations['Likes'] ? props.translations['Likes'] : 'Likes'}</li>
                                        : ''}
                                    <li className="favorite"><img src={require('../../public/img/' + favImg)} className="favorite" alt='fav_icon' onClick={handlerFav} />{props.translations['Favorite'] ? props.translations['Favorite'] : 'Favorite'}</li>
                                    {sharable === 'yes' || sharable === 'true' ? <li><img src={shareImg} alt='share_icon' onClick={() => {
                                        setEmbedOpenModal(true);
                                        (new ContentService()).SHareButtonClick(ContentIdModifier(content_id))
                                    }} /> {props.translations['Share'] ? props.translations['Share'] : 'Share'}</li> : null}
                                </ul>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Paper className='watch-title'>{<BlackTriangleVideoTitle title={contentTitle} />}</Paper>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Paper className='watch-speaker'>
                                    {props.translations['Speakers:'] ? props.translations['Speakers:'] : 'Speakers:'}&nbsp;
                                    {Array.isArray(speakers) && speakers.length > 0 ? (speakers.map((spr, index) => {
                                        let delimeter = (index === (speakers.length - 1)) ? '' : ', ';
                                        return (
                                            <Fragment key={spr.speaker_id}>
                                                <span key={index} className='speaker-name' onClick={() => handleSpeakerClick(spr)}>{spr.name}</span>
                                                {delimeter}
                                            </Fragment>
                                        )
                                    })) : "N/A"}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Paper className='watch-details'>{month}  / {viewCount + " "}
                                    {viewCount > 1 && (props.translations['Views'] ? props.translations['Views'] : 'Views')}
                                    {viewCount <= 1 && (props.translations['View'] ? props.translations['View'] : 'View')}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={12} className="content_watch_desc">
                                {showMoreactive === true ?
                                    <>
                                        <Paper className={showMore === false ? 'watch-desc-show' : 'watch-desc'}>
                                        {/* LILLYPLAY-3571 */}
                                        <RenderHTML showMore={showMore} htmlContent={videoDescription}/>
                                            {/* <div dangerouslySetInnerHTML={{ __html: BlackTriangleReplacer(showMore? videoDescription.slice(0, 100) + `${videoDescription.slice(0, 100).split(' ')[10]? `...`:``}`: videoDescription) }}></div> */}
                                        </Paper>
                                        {removeHtmlTags(videoDescription).length > 50 ?
                                            <button className='showmoreBtn' onClick={() => { SetshowMore(!showMore) }}>
                                                {!showMore && (props.translations['Show less'] ? props.translations['Show less'] : 'Show less')}
                                                {showMore && (props.translations['Show more'] ? props.translations['Show more'] : 'Show more')}
                                            </button>
                                            : null}
                                    </>
                                    :
                                    <Paper className='watch-desc-show'>
                                        <RenderHTML showMore={showMore} htmlContent={videoDescription}/>
                                        {/* LILLYPLAY-3571 */}
                                        {/* <div dangerouslySetInnerHTML={{ __html: BlackTriangleReplacer(videoDescription) }}></div> */}
                                    </Paper>
                                }
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                {Array.isArray(productCharacteristics) && productCharacteristics.length ? productCharacteristics.map((product, index) =>
                                    <BlackTriangleBrand brd_name={product.brand_name ? product.brand_name : product.displayText} brd_url={product.url} prdText={product.displayText} key={index}/>
                                ) : ''}
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                {tags && tags.length > 0 ? (
                                    <ul className='watch-keywordList'>{tags.map((tgs, index) => <li className="tagsClick" key={index} onClick={() => { openTag(tgs); }}><BlackTriangle title={tgs} /></li>)}</ul>
                                ) : ''}
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                {isHCP === 'false' && quizId !== '' ?
                                    <div>
                                        <Paper className='watch-quizHd'>{props.translations['Quiz'] ? props.translations['Quiz'] : 'Quiz'}</Paper>
                                        <Paper className='watch-quizsubHd'>{props.translations['Test your knowledge'] ? props.translations['Test your knowledge'] : 'Test your knowledge and gain a certificate'}</Paper>
                                        {isPlaylist ? (PlaylistEnd ? <button className='watch-start-quiz' onClick={() => { SetstartedDate(new Date()); setOpenModal(true) }}>{props.translations['Begin Quiz'] ? props.translations['Begin Quiz'] : 'Begin Quiz'}</button> : <button className='watch-start-quiz' disabled>{props.translations['Begin Quiz'] ? props.translations['Begin Quiz'] : 'Begin Quiz'}</button>) :
                                            (videoEnd ? <button className='watch-start-quiz' onClick={() => { SetstartedDate(new Date()); setOpenModal(true) }}>{props.translations['Begin Quiz'] ? props.translations['Begin Quiz'] : 'Begin Quiz'}</button> : <button className='watch-start-quiz' disabled>{props.translations['Begin Quiz'] ? props.translations['Begin Quiz'] : 'Begin Quiz'}</button>)
                                        }
                                        <AttemptQuiz translations={props.translations} openModal={openModal} handleClose={handleClose} quizId={quizId} startedDate={startedDate} oktaId={oktaId} contentID={contentID} isPlaylist={isPlaylist} contentTitle={contentTitle} hcpEmail={quizEmail} hcpCC={hcpCC} fullName={fullName} />
                                    </div>
                                    : null}
                            </Grid>
                            <Grid item xs={12} lg={12} className="content_watch_piinfo">
                                {category1Ids.length ? <PIInfo category1Ids={category1Ids} /> : ""}
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                {materials?.length > 0 ?
                                    <>
                                        <Paper className='watch-addHd'>{props.translations['Additional resources'] ? props.translations['Additional resources'] : 'Additional resources'}</Paper>
                                    </> : ''}
                                <Grid container>
                                    <Grid container className="desktop-Only">
                                        {materials !== null ?
                                            materials.length > 0 ? materials.map((material, index) => (
                                                    <Grid className='pdf-material' item xs={4} sm={4} lg={4} key={index}>
                                                        <Grid className='watch-resourceSec' onClick={() => {
                                                            contentService.MaterialsSelectedGAEvent(ContentIdModifier(content_id), material.filename);
                                                            if ((material.linkMask.trim()) !== '' && (material.linkUrl).trim() != '') {
                                                                window.open(material.linkUrl, '_blank').focus();
                                                            } else {
                                                                OpenPDF(material.file)
                                                            }

                                                        }}>
                                                            <img className='resource-img' src={thumbnail['image' + index] ? thumbnail['image' + index] : require('../../public/img/resource.png')} alt='resource_img' />
                                                            <Paper className='resource-cont'>{material.filename}</Paper>
                                                        </Grid>
                                                    </Grid>
                                            )) : ''
                                            // <>{props.translations['No materials available'] ? props.translations['No materials available'] : 'No materials available.'}</>
                                            : ''
                                            // <>{props.translations['No materials available'] ? props.translations['No materials available'] : 'No materials available.'}</>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className='mobile-container' container>
                                    <Grid className='slickLgSec mobile-Only'>
                                        <Slider {...settings}>
                                            {materials !== null ?
                                                materials.length > 0 ? materials.map((material, index) => (
                                                        <Grid className={slides === 3 ? 'sOuterlg' : 'sOuter'} key={index}>
                                                            <Grid className='pdf-material' item xs={12} sm={4} lg={4} key={index}>
                                                                <Grid className='watch-resourceSec' onClick={() => {
                                                                    contentService.MaterialsSelectedGAEvent(ContentIdModifier(content_id), material.filename);
                                                                    if ((material.linkMask.trim()) !== '' && (material.linkUrl).trim() != '') {
                                                                        window.open(material.linkUrl, '_blank').focus();
                                                                    } else {
                                                                        OpenPDF(material.file)
                                                                    }
                                                                }}>
                                                                    <img className='resource-img' src={thumbnail['image' + index] ? thumbnail['image' + index] : require('../../public/img/resource.png')} alt='resource_img' />
                                                                    <Paper className='resource-cont'>{material.filename}</Paper>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                )) : ''
                                                // <>{props.translations['No materials available'] ? props.translations['No materials available'] : 'No materials available.'}</>
                                                : ''
                                                // <>{props.translations['No materials available'] ? props.translations['No materials available'] : 'No materials available.'}</>
                                            }
                                        </Slider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5} md={4} sm={5}>
                        <Hidden xsDown>
                            {kaltura_id.indexOf('-') > -1 && Array.isArray(_playlistVideos) ?
                                <PlayList kalturaId={kalturaId}
                                    playlistID={playlistID} playlistTitle={playlistTitle}
                                    playlistVideos={_playlistVideos} isSeries={isSeries} imagearray={imagearray} />
                                : null}
                        </Hidden>
                        {relatedVideosFetchStatus ? <RelatedContent relatedVideos={relatedVideos} contentType={contentType} translations={props.translations} SetPositionMainPlayer={props.SetPositionMainPlayer} filterId={props.filterId} filter={props.filter} setVideoEnd = {setVideoEnd}/> :
                            <Grid className='shared-topTag text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={12}></Grid>
                </Grid>
            </Grid> :null }
        </Grid>
    )
}

export default ContentWatch;