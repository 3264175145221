/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable linebreak-style */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import './layout.css';
import Paper from '@material-ui/core/Paper';
import { AppFooterService } from './Services/AppFooter';
import countryListJson from '../CountryPassport/CountryPassport.json';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { setAffiliate } from '../Session/AffiliateSlice';
import config from '../../config/footerConfig.json';

const AppFooter = () => {
  const [jsonObj, SetjsonObj] = React.useState(countryListJson);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let affiliateSelector = useSelector(state=>state.affilaiteSlice);
  let categoriesSelector = useSelector(state=> state.categorySlice);
  function getCountryDetails(country_code) {
    return country_code !== undefined ? jsonObj.find(o => o.country_code === country_code) : '';
  }
  const [country_name, SetCountryName] = React.useState("");
  const [termsLink, SetTermsLink] = React.useState("");
  const [privacyLink, SetPrivacyLink] = React.useState("");
  const [copyrightLink, SetCopyrightLink] = React.useState("");
  const [accessibilityLink, SetAccessibilityLink] = React.useState("");
  const [vvpmApprovalCode, SetVVPMApprovalCode] = React.useState("");
  const [locale, SetLocale] = React.useState("");
  const [cookieEnable, SetCookieEnable] = React.useState(false);
  const [isHCPRole, SetIsHCPRole] = React.useState(false);
  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [openBanner, SetOpenBanner] = React.useState(false);
  const [redirectWaringModal, SetRedirectWaringModal] = React.useState(false);
  React.useEffect(() => {
    sessionState?.sessionValue?.payload?.roles?.forEach((role) => {
      if (role.role_id == 1) {
        SetIsHCPRole(true);
      }
    });
    let _locale = '';
    if(sessionState?.sessionValue?.payload?.user?.language){
      _locale = sessionState?.sessionValue?.payload?.user?.language;
      if(localStorage.getItem('MultiLang')){
        _locale = localStorage.getItem('MultiLang');
      }
      localStorage.setItem('MultiLang',_locale);
    }else{
      _locale = localStorage.getItem('MultiLang');
    }
    let languageLocale = localStorage.getItem('lang');
    SetLocale(languageLocale ?? '');
    let param = localStorage.getItem('country');
    // let param = sessionState?.sessionValue?.payload?.affiliate[0]?.country_name ?? '';
    if (param) {
      let footerService = new AppFooterService();
      footerService.GetAffiliateByQuery({ affiliate_id: param,   _dispatch: dispatch, _categoriesSelector:  categoriesSelector,_affiliateSelector : affiliateSelector, _setAffiliate:setAffiliate }).then((data) => {
        if (Array.isArray(data.data)){
        let countries = data.data;
        if (countries.length !== 0) {
          SetCountryName(countries[0].country_name)
          let countryDetails = getCountryDetails(countries[0].country_code);
          SetTermsLink(countryDetails.terms_link);
          SetPrivacyLink(countryDetails.privacy_link);
          SetCopyrightLink(countryDetails.copyright_link);
          SetAccessibilityLink(countryDetails.accessibility_link);
          SetVVPMApprovalCode(countryDetails.vvpm_approval_code);
          SetCookieEnable(countryDetails.cookie_enable);
        }
    }}).catch((err) => {})
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("click", changeLocale , true)
  }, []);

  const changeLocale = ()=>{
    // let locale = '';
    // if(sessionState?.sessionValue?.payload?.user?.language){
    //   locale = sessionState?.sessionValue?.payload?.user?.language
    // }else{
    let locale = localStorage.getItem('lang');
    //}
    SetLocale(locale ?? '');
  }
  const openCookieSettingsPopup = () => {
    window.Syrenis_OpenCookieOptionsModal();
  }

  const openBannerFunc = (open) => {
    if (country_name === "UK") {
      var uk_footer_scroll = document.querySelector(".collapsible");
      var uk_footer_scroll2 = document.querySelector("#footer-content-uk");
      if (open) {
        uk_footer_scroll?.classList.add("active");
        uk_footer_scroll2?.classList.add("active");
        document.querySelector(".content-uk-ft").style.display = 'block';
        document.querySelector(".footer-content-div").style.paddingBottom = '10px';
        document.querySelector(".footer-content-div").style.marginBottom = '0px';
      } else {
        uk_footer_scroll?.classList.remove("active");
        uk_footer_scroll2?.classList.remove("active");
        document.querySelector(".content-uk-ft").style.display = 'none';
        document.querySelector(".footer-content-div").style.paddingBottom = '0px';
      }
    }
  }
 
  React.useEffect(() => {
    let param = localStorage.getItem('country');
    if(param === 'UK'){
    setTimeout(() => {
      window.addEventListener('load', () => {
        var uk_footer_scroll = document.querySelector(".collapsible");
        var uk_footer_scroll2 = document.querySelector("#footer-content-uk");
        var container_height = document.querySelector(".container-height");
        var footer = document.querySelector(".fTxtSec");
        if (window.innerHeight >= container_height?.offsetHeight + footer?.offsetHeight) {
          uk_footer_scroll?.classList.add("active");
          uk_footer_scroll2?.classList.add("active");
          document.querySelector(".content-uk-ft").style.display = 'block';
          document.querySelector("#footer-content-uk").style.position = 'relative';
        } else {
          uk_footer_scroll?.classList.remove("active");
          uk_footer_scroll2?.classList.remove("active");
          document.querySelector(".content-uk-ft").style.display = 'none';
          document.querySelector("#footer-content-uk").style.position = 'fixed';
        }
      });
    }, 2000);
  
    window.addEventListener("scroll", () => {
      var uk_footer_scroll = document.querySelector(".collapsible");
      var uk_footer_scroll2 = document.querySelector("#footer-content-uk");
      var container_height = document.querySelector(".container-height");
      if (window.scrollY - 150 >= container_height?.offsetHeight - window.innerHeight) {
        uk_footer_scroll?.classList.add("active");
        uk_footer_scroll2?.classList.add("active");
        document.querySelector(".content-uk-ft").style.display = 'block';
        document.querySelector("#footer-content-uk").style.position = 'relative';
      } else {
        uk_footer_scroll?.classList.remove("active");
        uk_footer_scroll2?.classList.remove("active");
        document.querySelector(".content-uk-ft").style.display = 'none';
        document.querySelector("#footer-content-uk").style.position = 'fixed';
      }
    });
  }
  }, [])

  const handlePILinkClick = () => {
    SetRedirectWaringModal(true);
  }
  return (
    <>
      {/* {country_name === "UK" ?
        <Accordion className='safeSec'>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className='safehd'>
            Safety and adverse event reporting
          </AccordionSummary>
          <AccordionDetails className='footerBck'>
            <Grid className='safeInner'>
              <Paper className='texthd'>Important saftey information</Paper>
              <Paper className='textsubhd'>Adverse events and product complaints should be reported. Reporting forms and information can be found at {country_name}: <Link className='changeColor'>www.mhra.gov.uk/yellowcard </Link>
                or search for MHRA Yellow Card in the Google Play or Apple App Store. Adverse events and product complaints should also be reported to Lilly: please call Lilly UK on 01256 315 000
              </Paper>
            </Grid>
          </AccordionDetails>
        </Accordion>
        : ""} */}
         <Modal open={redirectWaringModal} onClose={() => { SetRedirectWaringModal(false) }}>
            <Grid className='paper modalstyle redirectWarningModal'>
                <Grid className="modal-body">
                    <button className='closeModal' onClick={() => SetRedirectWaringModal(false)}></button>
                    <div className="m20">
                        <div className="text-center">
                            <h3>You are now leaving {window.location.origin}</h3>
                            <p>The link you clicked on will take you to a site maintained by a third party, which is solely responsible for its content. Eli Lilly Company and its affiliates do not control, influence, or endorse this site, and the opinions, claims, or comments expressed on this site should not be attributed to Eli Lilly Company and its affiliates. Eli Lilly Company and its affiliates are not responsible for the  privacy policy of any third-party websites. We encourage you to read the privacy policy of every website you visit.</p>
                            <p>Click "Continue" to leave site or "Return to Lilly Play" to close this window and return to {window.location.origin}</p>
                        </div>
                    </div>
                </Grid>
                <Grid className="modal-footer">
                    <button className='modalBtn' onClick={() => { SetRedirectWaringModal(false) }}>Return to Lilly Play</button>
                    <button type="button" onClick={() => { window.open(config?.mhraGovUK, '_blank'); SetRedirectWaringModal(false); }} className="modalBtn">Continue</button>
                </Grid>
            </Grid>
        </Modal>
        {country_name === "UK" && 
        <div id="footer-content-uk" className='footer-content-div'>
        <button type="button" className="collapsible" onClick={() => {openBannerFunc(!openBanner);SetOpenBanner(!openBanner)}}>SAFETY AND ADVERSE EVENT REPORTING</button>
        <div className="content-uk-ft">
          <div className="elds">
            <div className="isi-wrapper">
              <div className="isi brand-colors-abasaglar">
                <div className="isi-content">	      
                  <section>
                    <div className="container">	          
                      <div className="isi-section box-warning safeInner">
                        <h4 className='texthd'>IMPORTANT SAFETY INFORMATION</h4>
                        <div className="card textsubhd">
                            <div className="card-section">
                              <b>
                                Adverse events and product complaints should be reported. Reporting forms and information can be found at UK: <a className='changeColor' id="openNewWin" onClick={() => handlePILinkClick()} href="#">www.mhra.gov.uk/yellowcard</a> or search for MHRA Yellow Card in the Google Play or Apple App Store. Adverse events and product complaints should also be reported to Lilly: please call Lilly UK on 01256 315 000.
                              </b>
                            </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        }
      {isHCPRole &&
        <>
             {country_name === "Korea" ?
              <Grid container className='fTxtSec'>
                <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                  <Paper className='fTxt'>©{new Date().getFullYear()} Eli Lilly and Company. All rights reserved</Paper>
                  <Paper className='fTxt'>이 사이트는 대한민국에 거주하는 보건 의료 전문가들을 대상으로 개발되었습니다.</Paper>
                </Grid>
                <Grid item xs={12} lg={6} md={7} className='flistSec'>
                  <Paper className='fTxt'>
                    {/* {new Date().toLocaleDateString('default', { month: 'short' })} {new Date().getFullYear()}  */}PP-MG-KR-0216
                  </Paper>
                  <Paper className='fTxt'>
                    <ul className='flistOuter'>
                      <li className='flist'> <a target="_blank" href={config?.lillyURL  + '/kr/terms-of-use'} rel="noreferrer">이용약관</a></li>
                      <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyURL  + '/kr/privacy'} rel="noreferrer">개인정보취급방침</a></li>
                      <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyURL  + '/kr/accessibility-statement'} rel="noreferrer">접근성</a></li>
                      {/* {accessibilityLink !== '' ? <li className='flist'><span className='bar'>|</span> <a target="_blank" href={accessibilityLink} rel="noreferrer">Accessibility Statement</a></li> : ""} */}
                      {cookieEnable ? <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup}>Cookie Settings</a></li> : ""}
                    </ul>
                  </Paper>

                </Grid>
              </Grid>
              : country_name === "Turkey" ?
                <Grid container className='fTxtSec'>
                  <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                <Paper className='fTxt'>
                   <p>Ürünlerimizin güncel fiyatlarına <a style={{ color: '#fff', textDecoration:'underline' }} href={config?.tinyurl}> https://tinyurl.com/satisfiyat </a> adresinden ulaşabilirsiniz. <br /><br />Yan etki bildirimlerini 24 saat içinde Farmakovijilans Departmanı’na; 0216 554 00 00  numaralı<br/> telefondan 2’yi tuşlayarak Yan Etki Bildirim Hattı aracılığıyla, <a style={{ color: '#fff', textDecoration:'underline' }} href="mailto:pharmacovigilance_turkey@lilly.com">pharmacovigilance_turkey@lilly.com</a> adresine e-posta yollayarak ya da 0216 554 01 81 numaralı faksa gönderim yaparak bildirebilirsiniz.</p> 
                  Bu platformda kaydınızın olmasını istemiyorsanız lütfen <span className='flist'><a style={{ textDecoration:'underline' }} href="mailto:Site_Sorumlusu@lilly.com">Site_Sorumlusu@lilly.com</a></span>  adresine bildiriniz.
                </Paper>
                    <Paper className='fTxt'>Her hakkı Lilly İlaç Tic. Ltd. Şti’ye aittir. © {new Date().getFullYear()} | KOD: PP-LILLY-TR-0591<br /><br />Bu platform sağlık mesleği mensuplarına yöneliktir.</Paper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={7} className='flistSec'>
                    <Paper className='fTxt'>
                      <ul className='flistOuter'>
                        <li className='flist'> <a target="_blank" href={config?.lillyTR  + "/yasal-uyari"} rel="noreferrer">YASAL UYARI</a></li>
                        <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/TR-tr/hcp"} rel="noreferrer">GİZLİLİK BEYANI</a></li>
                        <li className='flist'><span className='bar'>|</span> <a style={{ cursor: 'pointer'}} onClick={() => navigate('/contact-us')} rel="noreferrer">İLETİŞİM</a></li>
                        <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyTR  + "/erisilebilirlik-bildirimi"} rel="noreferrer">ERİŞEBİLİRLİK BİLDİRİMİ</a></li>

                      </ul>
                    </Paper>
                    {/* <Paper className='fTxt'>
              {new Date().toLocaleDateString('default', { month: 'short' })} {new Date().getFullYear()} {vvpmApprovalCode}
            </Paper> */}
                  </Grid>
                </Grid>
                : country_name === "France" ?
                  <Grid container className='fTxtSec'>
                    <Grid item xs={12} lg={6} md={5} sm={5} className='fcpyrghtSec'>
                      <Paper className='flist fTxt'><a href={config?.lillyFR + "/fr/copyright.aspx"} target="_blank" rel="noreferrer">Copyright</a> © {new Date().getFullYear()} Lilly France Tous droits de reproduction réservés</Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} md={7} sm={7} className='flistSec'>
                      <Paper className='fTxt'>
                        <ul className='flistOuter'>
                          <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/fr-fr/hcp"} rel="noreferrer">Protection des données personnelles</a></li>
                          <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillypro} rel="noreferrer">Mentions légales</a></li>
                          <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyFR + "/declaration-accessibilite"} rel="noreferrer">Déclaration d’accessibilité</a></li>
                          <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Paramètres des cookies</a></li>
                        </ul>
                      </Paper>
                      <Paper className='fTxt'> PP-MG-FR-1687 - Décembre 2024</Paper>
                    </Grid>
                  </Grid>
                  : country_name === "Brazil" ?
                    <Grid container className='fTxtSec'>
                      <Grid item xs={12} lg={12} md={12} className='fTxt'>
                        Você deve estar atento que este site não tem a intenção, nem foi desenhado para atrair, indivíduos abaixo de 18 anos.<br /> Nós não coletamos informações pessoais identificáveis de qualquer pessoa abaixo de 18 anos de idade.
                      </Grid>

                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                        <Paper className='fTxt'>Copyright ©1994-{new Date().getFullYear()} Eli Lilly and Company.<br />Todos os direitos reservados.</Paper>

                      </Grid>
                      <Grid item xs={12} lg={6} md={7} className='flistSec'>

                        <Paper className='fTxt'>
                          <ul className='flistOuter'>
                            <li className='flist'> <a target="_blank" href={config?.lillyBR  + "/termos-de-uso"} rel="noreferrer">Termos de uso</a></li>
                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/BR-pt/hcp"} rel="noreferrer">Política de Privacidade</a></li>
                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyBR  + "/declaracao-de-acessibilidade"} rel="noreferrer">Declaração de Acessibilidade</a></li>
                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyBR  + "/contato"} rel="noreferrer">Contatos</a></li>
                            <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Ajustes de cookie</a></li>

                          </ul>
                        </Paper>
                        <Paper className='fTxt'>
                        PP-NB-BR-0148

                        </Paper>
                      </Grid>
                    </Grid>
                    : (country_name === "Argentina" || country_name === "Aruba" || country_name === "Bahamas"
                      || country_name === "Barbados" || country_name === "Belize" || country_name === "Bolivia"
                      || country_name === "Chile" || country_name === "Colombia" || country_name === "Costa Rica"
                      || country_name === "Curacao" || country_name === "Dominican Republic" || country_name === "Ecuador"
                      || country_name === "El Salvador" || country_name === "Guatemala" || country_name === "Guyana"
                      || country_name === "Honduras" || country_name === "Jamaica" || country_name === "Mexico"
                      || country_name === "Nicaragua" || country_name === "Panama" || country_name === "Paraguay"
                      || country_name === "Peru" || country_name === "Trinidad and Tobago" || country_name === "Uruguay" || country_name === "Venezuela") ?
                      <Grid container className='fTxtSec'>

                        <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                          <Paper className='fTxt'>Material exclusivo para profesionales de la salud</Paper>
                          <Paper className='fTxt'>Todos los derechos reservados Eli Lilly & Co {new Date().getFullYear()}</Paper>
                        </Grid>
                        <Grid item xs={12} lg={6} md={7} className='flistSec'>

                          <Paper className='fTxt'>
                            <ul className='flistOuter'>
                              <li className='flist'> <a target="_blank" href={config?.lillyLatam  + "/t%C3%A9rminos-de-uso"} rel="noreferrer">Términos de uso</a></li>
                              <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/LACAM-es/hcp"} rel="noreferrer">Política de Privacidad</a></li>
                              <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyLatam  + "/declaracion-de-accesibilidad"} rel="noreferrer">Declaración de Accesibilidad</a></li>
                            </ul>
                          </Paper>
                          <Paper className='fTxt'>
                            PP-MG-MX-0079 | 07/2021 - SSA 2309042002C00475
                          </Paper>
                        </Grid>
                      </Grid>
                      : (country_name === "Kuwait" || country_name === "Oman" || country_name === "Qatar"
                        || country_name === "Iraq" || country_name === "Pakistan" || country_name === "Libya"
                        || country_name === "Lebanon" || country_name === "United Arab Emirates") ?
                        <Grid container className='fTxtSec'>

                          <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                            <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company. All rights reserved.</Paper>
                            <Paper className='fTxt'>
                              This webpage is intended for healthcare professionals only.<br />
                              For adverse events and safety reporting, please send an email to <span className='flist'>
                            {country_name === "Pakistan" ? <a className='flist' href="mailto:pk-phv@lilly.com">pk-phv@lilly.com</a>
                              : <a className='flist' href="mailto:PV-MEA@lilly.com">PV-MEA@lilly.com</a>}
                               </span>
                          {country_name !== "Pakistan" &&
                          <><br /><span className='flist'>
                              For product complaints, please send an email: <a href="mailto:lbmail_pc_mena@lilly.com">lbmail_pc_mena@lilly.com</a>
                            </span></>}
                            </Paper>
                          </Grid>
                          <Grid item xs={12} lg={6} md={7} className='flistSec'>

                            <Paper className='fTxt'>
                              <ul className='flistOuter'>
                                <li className='flist'> <a target="_blank" href={"/landing/mea/terms-of-use.html"} rel="noreferrer">Terms of Use</a></li>
                                <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/mea-en/hcp"} rel="noreferrer">Privacy Policy</a></li>
                                <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/mea/accessibility_link.html"} rel="noreferrer">Accessibility Statement</a></li>
                                <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/mea/contact-us.html"} rel="noreferrer">Contact Us</a></li>
                              </ul>
                            </Paper>
                            <Paper className='fTxt'>
                              PP-LILLY-AE-0039
                            </Paper>
                          </Grid>
                        </Grid>
                        : country_name === "Germany" ?
                          <Grid container className='fTxtSec'>
                            <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                              <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Lilly Deutschland GmbH.</Paper>
                              <Paper className='fTxt'>PP-LILLY-DE-0202</Paper>

                            </Grid>
                            <Grid item xs={12} lg={6} md={7} className='flistSec'>
                              <Paper className='fTxt'>
                                <ul className='flistOuter'>
                                  <li className='flist'> <a target="_blank" href={config?.lillyPharma  + "/impressum"} rel="noreferrer">Impressum</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/DE-de/hcp"} rel="noreferrer">Datenschutz</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPharma  + "/nutzungsbedingungen"} rel="noreferrer">Nutzungsbedingungen</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPharma  + "/barrierefreiheits"} rel="noreferrer">Erklärung zur Barrierefreiheit</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Cookie Einstellungen</a></li>
                                </ul>
                              </Paper>
                            </Grid>
                          </Grid>
                          : country_name === "Belgium" ?
                            <Grid container className='fTxtSec'>
                              <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company. All rights reserved. {locale=== 'fr-BE'? 'PP-MG-BE-0081':'PP-MG-BE-0099'}</Paper>
                              </Grid>
                              <Grid item xs={12} lg={6} md={7} className='flistSec'>
                              {(locale === 'fr-FR' || locale === 'fr-BE') ?
                              <Paper className='fTxt'>
                                <ul className='flistOuter'>
                                  <li className='flist'> <a target="_blank" href={config?.lillyBE + "/fr-BE/our-medicines/current-medicines"} rel="noreferrer">Notices Scientifiques</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyBE + "/fr-BE/copyright"} rel="noreferrer">Copyright</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyBE + "/fr-BE/terms-of-use"} rel="noreferrer">Dispositions Légales</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/BE-FR/hcp"} rel="noreferrer">Déclaration de Confidentialité</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Paramètres des cookies</a></li>
                                </ul>
                              </Paper> : 
                              <Paper className='fTxt'>
                                <ul className='flistOuter'>
                                  <li className='flist'> <a target="_blank" href={config?.lillyBE + "/nl/onze-producten/geneesmiddelen.aspx"} rel="noreferrer">Wetenschappelijke Bijsluiters</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyBE + "/nl/copyright-information.aspx"} rel="noreferrer">Copyright</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyBE + "/nl/aansprakelijk.aspx"} rel="noreferrer">Aansprakelijkheid</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/BE-nl/hcp"} rel="noreferrer">Privacyverklaring</a></li>
                                  <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Cookie-Einstellungen</a></li>
                                </ul>
                              </Paper>}
                              </Grid>
                            </Grid>
                            : country_name === "Netherlands" ?
                              <Grid container className='fTxtSec'>
                                <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                  <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company. All rights reserved.</Paper>
                                </Grid>
                                <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                  <Paper className='fTxt'>
                                    <ul className='flistOuter'>
                                      <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/nl-nl/hcp"} rel="noreferrer">Privacy verklaring</a></li>
                                      <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyNL + "/disclaimer"} rel="noreferrer">Disclaimer</a></li>
                                      <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyNL + "/Toegankelijkheidsverklaring"} rel="noreferrer">Toegankelijkheidsverklaring</a></li>
                                      <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyNL + "/copyright"} rel="noreferrer">Copyright</a></li>
                                      <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Cookie-instellingen</a></li>
                                    </ul>
                                  </Paper>
                                  <Paper className='fTxt'>
                                  PP-MG-NL-0105
                                  </Paper>
                                </Grid>
                              </Grid>
                              : country_name === "Spain" ?
                                <Grid container className='fTxtSec'>
                                  <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                    <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company. Todos los derechos reservados. Este sitio Web ha sido publicado por Lilly, S.A.U.</Paper>
                                  </Grid>
                                  <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                    <Paper className='fTxt'>
                                      <ul className='flistOuter'>
                                        <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/es-es/hcp"} rel="noreferrer">Política de Privacidad</a></li>
                                        <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.cloudfrontTermPdfSpain} rel="noreferrer">Términos y Condiciones de Uso</a></li>
                                        <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.cloudfrontCopyrightPdfSpain} rel="noreferrer">Copyright</a></li>
                                        <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.cloudfrontPolicyPdfSpain} rel="noreferrer"> Política de Cookies</a></li>
                                        <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.cloudfrontDeclarePdfSpain} rel="noreferrer">Declaración de Accesibilidad</a></li>
                                        <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Configuración de las cookies</a></li>
                                      </ul>
                                    </Paper>
                                    <Paper className='fTxt'>
                                    PP-LILLY-ES-1220
                                    </Paper>
                                  </Grid>
                                </Grid>
                                : country_name === "Saudi Arabia" ?
                                  <Grid container className='fTxtSec'>
                                    <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                      <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company.</Paper>
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                      <Paper className='fTxt'>
                                        <ul className='flistOuter'>
                                          <li className='flist'> <a target="_blank" href={config?.lillySA  + "/en-SA/terms-of-use"} rel="noreferrer">Terms of Use</a></li>
                                          <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/SA-en/hcp"} rel="noreferrer">Privacy Policy</a></li>
                                          <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySA  + "/en-SA/accessibility-statement"} rel="noreferrer">Accessibility Statement</a></li>
                                          <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySA  + "/en-SA/contact"} rel="noreferrer">Contact Us</a></li>
                                          <li className='flist'><span className='bar'>|</span> <a style={{ cursor: 'pointer'}} onClick={() => navigate('/audience-age')} rel="noreferrer">Audience Age Disclaimer</a></li>
                                        </ul>
                                      </Paper>
                                      <Paper className='fTxt'>
                                      PP-LILLY-SA-0185
                                      </Paper>
                                    </Grid>

                                  </Grid>
                                  : country_name === "Australia" ?
                                    <Grid container className='fTxtSec'>
                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                        <Paper className='fTxt'>©{new Date().getFullYear()} Copyright Eli Lilly and Company. Eli Lilly Australia Pty Ltd. ABN 39 000 233 993. Level 9, 60 Margaret St, Sydney NSW 2000.</Paper>
                                        <Paper className='fTxt'>This website is intended for Australian Healthcare Professionals only.<br />
                                          Date of Preparation: March 2023</Paper>
                                      </Grid>
                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                        <Paper className='fTxt'>
                                          <ul className='flistOuter'>
                                            <li className='flist'> <a target="_blank" href={config?.lillyAU  + "/en/terms-of-use.aspx"} rel="noreferrer"> Terms of Use</a></li>
                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/au-en/hcp"} rel="noreferrer">Privacy Policy</a></li>
                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyAU  + "/accessibility-statement"} rel="noreferrer">Accessibility Statement</a></li>
                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyAU  + "/contact"} rel="noreferrer">Contact Us</a></li>
                                          </ul>
                                        </Paper>
                                        <Paper className='fTxt'>
                                        PP-LILLY-AU-0097
                                        </Paper>
                                      </Grid>

                                    </Grid>
                                    : country_name === "New Zealand" ?
                                      <Grid container className='fTxtSec'>
                                        <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                          <Paper className='fTxt'>©{new Date().getFullYear()} Copyright Eli Lilly and Company. Eli Lilly and Company (NZ) Limited. NZBN: 9429039560643. Level 27, 88 Shortland Street, Auckland, 1010, New Zealand.</Paper>
                                          <Paper className='fTxt'>This website is intended for New Zealand Healthcare Professionals only. <br />
                                            Date of Preparation: March 2023</Paper>

                                        </Grid>
                                        <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                          <Paper className='fTxt'>
                                            <ul className='flistOuter'>
                                              <li className='flist'> <a target="_blank" href={config?.lillyNZ  + "/terms-of-use"} rel="noreferrer"> Terms of Use</a></li>
                                              <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/nz-en/hcp"} rel="noreferrer">Privacy Policy</a></li>
                                              <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyNZ  + "/accessibility-statement"} rel="noreferrer">Accessibility Statement</a></li>
                                              <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyNZ  + "/contact"} rel="noreferrer">Contact Us</a></li>
                                            </ul>
                                          </Paper>
                                          <Paper className='fTxt'>
                                          PP-LILLY-AU-0096
                                          </Paper>
                                        </Grid>

                                      </Grid>
                                      : country_name === "India" ?
                                        <Grid container className='fTxtSec'>
                                          <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                            <Paper className='fTxt'>Copyright @ 2023 Eli Lilly and Company. All rights reserved</Paper>
                                          </Grid>
                                          <Grid item xs={12} lg={6} md={7} className='flistSec'>

                                            <Paper className='fTxt'>
                                              <ul className='flistOuter'>
                                                <li className='flist'> <a target="_blank" href={"/landing/india/terms-of-use.html"} rel="noreferrer"> Terms of Use</a></li>
                                                <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/IN-en/hcp"} rel="noreferrer">Privacy Policy</a></li>
                                                <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyIndia  + "/contact"} rel="noreferrer">Contact Us</a></li>
                                              </ul>
                                            </Paper>
                                            <Paper className='fTxt'>
                                              PP-LILLY-IN-0091
                                            </Paper>
                                          </Grid>

                                        </Grid>
                                        : country_name === "South Africa" ?
                                          <Grid container className='fTxtSec'>
                                            <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                              <Paper className='flist fTxt'>Copyright © {new Date().getFullYear()} <a style={{ textDecoration: 'underline' }} href={config?.lillyURL} target="_blank">Lilly</a> South Africa.</Paper>
                                            </Grid>
                                            <Grid item xs={12} lg={6} md={7} className='flistSec'>

                                              <Paper className='fTxt'>
                                                <ul className='flistOuter'>
                                                  <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/ZA-en/hcp"} rel="noreferrer">Privacy Policy</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/southafrica/accessibility-statement.html"} rel="noreferrer">Accessibility Statement</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/southafrica/contact-us.html"} rel="noreferrer">Contact Us</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/southafrica/terms-of-use.html"} rel="noreferrer">Terms of Use</a></li>
                                                </ul>
                                              </Paper>
                                              <Paper className='fTxt'>
                                                PP-MG-ZA-0011
                                              </Paper>
                                            </Grid>

                                          </Grid>
                                          : country_name === "Switzerland" ?
                                            <Grid container className='fTxtSec'>
                                              <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                <Paper className='flist fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly (Suisse) SA All rights reserved.</Paper>
                                              </Grid>
                                              <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                <Paper className='fTxt'>
                                              {(locale == 'de-DE' || locale == 'de-CH') ?
                                                <ul className='flistOuter'>
                                                  <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/CH-de/hcp"}>Datenschutzerklärung</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCH + "/de/allgemeine-nutzungsbedingunen.aspx"} rel="noreferrer">Allgemeine Nutzungsbedingungen</a>
                                                  </li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCH + "/de-CH/kontakt"} rel="noreferrer">Kontakt</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCH + "/de/copyright.aspx"} rel="noreferrer">Copyright</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Persönliche Cookie-Einstellungen</a></li>

                                                </ul>
                                                :
                                                <ul className='flistOuter'>
                                                  <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/CH-fr/hcp"}>Protection des données personelles</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCH + "/fr/dispositions-generales.aspx"} rel="noreferrer">Dispositions générales</a>
                                                  </li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCH + "/fr-CH/nous-contacter"} rel="noreferrer">Contact</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCH + "/fr/copyright-information.aspx"} rel="noreferrer">Copyright</a></li>
                                                  <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Configuration des cookies</a></li>

                                                </ul>}
                                                </Paper>
                                              {(locale == 'de-DE' || locale == 'de-CH') ?
                                                <Paper className='fTxt'>
                                                  PP-LILLY-CH-0310 <span style={{ color: '#fff' }}> Letzte Aktualisierung: {new Date().getMonth() + 1}/{new Date().getFullYear()}</span>
                                                </Paper> :
                                                <Paper className='fTxt'>
                                                  PP-LILLY-CH-0310 <span style={{ color: '#fff' }}> Dernière actualisation: {new Date().getMonth() + 1}/{new Date().getFullYear()} </span>
                                                </Paper>}
                                              </Grid>

                                            </Grid>
                                             : country_name === "Austria" ?
                                             <Grid container className='fTxtSec'>
                                               <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                  <Paper className='flist fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly GmbH. Alle Rechte vorbehalten. PP-LILLY-AT-0052, März 2023</Paper>
                                               </Grid>
                                               <Grid item xs={12} lg={6} md={7} className='flistSec'>
   
                                                 <Paper className='fTxt'>
                                                   <ul className='flistOuter'>
                                                     <li className='flist'> <a target="_blank" href={config?.lillyAT + "/de/impressum.aspx"}>Impressum</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/AT-de/hcp"} rel="noreferrer">Datenschutzerklärung</a>
                                                     </li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyAT + "/de/nutzungsbedingungen.aspx"} rel="noreferrer">Nutzungsbedingungen</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyAT + "/barrierefreiheit"} rel="noreferrer">Erklärung zur Barrierefreiheit</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Cookie-Einstellungen</a></li>
   
                                                   </ul>
                                                 </Paper>
                                                
                                               </Grid>
   
                                             </Grid>
                                             : country_name === "Finland" ?
                                             <Grid container className='fTxtSec'>
                                               <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                 <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company. All rights reserved.</Paper>
                                                 <Paper className='fTxt'>Abasaglar®, Retsevmo®, Baqsimi®, Emgality®, Zypadhera®, Liprolog®, Olumiant®, Taltz®, Trulicity®, Verzenios® and Lilly are registered trademarks of Eli Lilly and Company.</Paper>
                                               </Grid>
                                               <Grid item xs={12} lg={6} md={7} className='flistSec'>
   
                                                 <Paper className='fTxt'>
                                                   <ul className='flistOuter'>
                                                     <li className='flist'> <a target="_blank" href={config?.lillyFI + "/terms-of-use"} rel="noreferrer">Käyttöehdot</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/fi-fi/hcp"} rel="noreferrer">Tietosuoja</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyFI + "/accessibilty-statement"} rel="noreferrer">Saavutettavuuslausunto</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyFI + "/copyright"} rel="noreferrer">Copyright</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Evästeasetukset</a></li>
                                                   </ul>
                                                 </Paper>
                                                 <Paper className='fTxt'>
                                                 Oy Eli Lilly Finland Ab, Laajalahdentie 23, Helsinki, (09) 854 5250 | PP-MG-FI-0198 11.2023
                                              </Paper>
                                               </Grid>
   
                                             </Grid>
                                             : country_name === "Portugal" ?
                                             <Grid container className='fTxtSec'>
                                               <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                 <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company</Paper>
                                                 
                                               </Grid>
                                               <Grid item xs={12} lg={6} md={7} className='flistSec'>
   
                                                 <Paper className='fTxt'>
                                                   <ul className='flistOuter'>
                                                     <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/PT-pt/hcp"} rel="noreferrer">política de privacidade e utilização de cookies</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPT + "/termos-condicoes"} rel="noreferrer">termos e condições</a>
                                                     </li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPT + "/declaracao-de-acessibilidade"} rel="noreferrer">Declaração de Acessibilidade</a>
                                                     </li>
                                                     <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">configurações de cookies</a></li>
   
                                                   </ul>
                                                 </Paper>
                                                 <Paper className='fTxt'>
                                                 PP-LILLY-PT-0052/{new Date().toLocaleDateString('default', { month: 'short' }).toLocaleUpperCase()}{new Date().getFullYear()} 
                                              </Paper>
                                               </Grid>
   
                                             </Grid>
                                             : country_name === "Sweden" ?
                                             <Grid container className='fTxtSec'>
                                               <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                 <Paper className='flist fTxt'>För fullständiga produktresuméer, se <a style={{textDecoration:'underline'}} href={config?.fass} target="_blank">www.fass.se
                                                 </a></Paper>
                                                 <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company</Paper>
                                                 
                                               </Grid>
                                               <Grid item xs={12} lg={6} md={7} className='flistSec'>
   
                                                 <Paper className='fTxt'>
                                                   <ul className='flistOuter'>
                                                     <li className='flist'> <a target="_blank" href={config?.lillySE + "/terms-of-use"}>Terms of Use</a></li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/SE-sv/hcp"} rel="noreferrer">Privacy Policy</a>
                                                     </li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySE + "/accessibility-statement"} rel="noreferrer">Tillgänglighetsutlåtande</a>
                                                     </li>
                                                     <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySE + "/copyright"} rel="noreferrer">Copyright</a>
                                                     </li>
                                                     <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Cookie-inställningar</a></li>
   
                                                   </ul>
                                                 </Paper>
                                                 <Paper className='fTxt'>
                                                 Eli Lilly Sweden AB, Gustav III Boulevard 42, Solna, 08-737 88 00 | PP-MG-SE-0232/03.2023
                                              </Paper>
                                               </Grid>
   
                                             </Grid>
                                              : country_name === "Slovakia" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company.<br />Eli Lilly Slovakia s.r.o. Svätoplukova II. 18892/2 A, Bratislava - mestská časť Ružinov 821 08.
                                                          Táto adresa sa vzťahuje na všetky materiály prezentované na týchto stránkach a vytvorené pred zmenou sídla 1.7.2022<br />
                                                          Tieto stránky sú určené výhradne poskytovateľom zdravotníckej starostlivosti</Paper>


                                                      </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                        <Paper className='fTxt'>
                                                        PP-MG-SK-0033<br />Dátum schválenia: 3/2023
                                                        </Paper>
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                          <li className='flist'> <a target="_blank" href={config?.lillySK + "/lieky/nase-produkty/lieky"}>INFORMÁCIE O ÚHRADE</a></li>
                                                            <li className='flist'><span className='bar'>|</span>  <a target="_blank" href={config?.lillyPrivacy  + "/SK-sk/hcp"}>OCHRANA OSOBNÝCH ÚDAJOV A COOKIES</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySK + "/obchodne-podmienky"} rel="noreferrer">ZMLUVNÉ PODMIENKY</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySK + "/autorske-prava"} rel="noreferrer">AUTORSKÉ PRÁVA</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySK + "/vyhlasenie-o-pristupnosti"} rel="noreferrer">VYHLÁSENIE O PRÍSTUPNOSTI</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillySK + "/kontakt"} rel="noreferrer">KONTAKT</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">NASTAVENIA SÚBOROV COOKIE</a></li>

                                                          </ul>
                                                        </Paper>

                                                      </Grid>

                                                    </Grid>
                                                    : country_name === "Czech" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='flist fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company.</Paper>
                                                        <br />
                                                        <Paper className='flist fTxt'>Tato stránka je určena výhradně poskytovatelům zdravotní péče</Paper>
                                                      </Grid>
                                                      
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      <Paper className='fTxt'>
                                                      PP-MG-CZ-0061
                                                     </Paper>
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyCZ + "/smluvni-podminky"} rel="noreferrer">SMLUVNÍ PODMÍNKY</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/cz-cs/hcp"} rel="noreferrer">OCHRANA OSOBNÍCH ÚDAJŮ A COOKIES</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCZ + "/autorska-prava"} rel="noreferrer">AUTORSKÁ PRÁVA</a></li>
                                                            <li style={{textTransform: 'uppercase'}} className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCZ + "/prohlaseni-o-pristupnosti"} rel="noreferrer">Prohlášení o přístupnosti</a></li>
                                                            <li style={{textTransform: 'uppercase'}} className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyCZ + "/kontakt"} rel="noreferrer">Kontakt</a></li>
                                                            <li style={{textTransform: 'uppercase'}} className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/czech/Informace-o-úhradě.html"} rel="noreferrer">Informace o úhradě</a></li>
                                                            <li style={{textTransform: 'uppercase'}} className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Nastavení souborů cookie</a></li>
                                                          </ul>
                                                        </Paper>
                                                      </Grid>
                                                    </Grid>
                                                    : country_name === "Taiwan" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company. All rights reserved.</Paper>
                                                        <Paper className='fTxt'>
                                                        P-MKT-006-24-Jun-14<br/>
                                                        本網站僅供醫療保健專業人員使用。
                                                     </Paper>
                                                      </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyTW  + "/terms-of-use"}>使用條款</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/tw-zh/hcp"} rel="noreferrer">隱私權政策</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyTW  + "/accessibilty-statement"} rel="noreferrer">造訪便利性聲明</a>
                                                            </li>
                                                          </ul>
                                                        </Paper>
                                                        
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Morocco" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='flist fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company.<span className="algeria-text">| PP-LILLY-MA-0016</span></Paper>
                                                        
                                                      </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyPrivacy  + "/MA-fr/hcp"} rel="noreferrer">Politique de confidentialité et conditions d’utilisation</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/morocco/contact-us.html"} rel="noreferrer">Nous contacter</a>
                                                            </li>
                                                           
                                                          </ul>
                                                        </Paper>
                                                        
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Hong Kong" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company.<br />This site is intended for Hong Kong Healthcare Professionals.</Paper>
                                                        <Paper className='fTxt'>Eli Lilly Asia, Inc.<br />Unit 3203-06, 32/F, Chubb Tower, Windsor House,<br />
                                                                  311 Gloucester Road, Causeway Bay, Hong Kong<br />
                                                                  General: 852 2572 0160  &nbsp; &nbsp; &nbsp; &nbsp;   Fax: (852) 2572 7893<br />
                                                                  <span className='flist'>
                                                                  <a href={config?.lillyHK} target="_blank" >www.lilly.com.hk</a>
                                                                    </span></Paper>
                                                        
                                                      </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyHK  + "/terms-of-use"}>Terms of Use</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/HK-en/hcp"} rel="noreferrer">Privacy Policy</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyHK  + "/accessibilty-statement"} rel="noreferrer">Accessibility</a>
                                                            </li>
                                                          </ul>
                                                        </Paper>
                                                        <Paper className='fTxt'>PP-LILLY-HK-0003 0{new Date().getMonth()}/{new Date().getFullYear()}</Paper>
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Russia" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>©{new Date().getFullYear()}. Copyright ООО «Лилли Фарма». Все права защищены.</Paper>
                                                        </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={"/landing/russia/terms-of-use.html"} rel="noreferrer">Пользовательское соглашение</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/russia/privacy-policy.html"} rel="noreferrer">Положение о конфиденциальности</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/russia/accessibility_link.html"} rel="noreferrer">Положение о сетевой доступности</a></li>
                                                          </ul>
                                                        </Paper>
                                                        <Paper className='fTxt'>PP-LILLY-RU-0054 18 Nov 2020</Paper>
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Romania" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='flist fTxt'><a style={{textDecoration:'underline'}} href="/landing/romania/INFORMAȚII.html" target="_blank">Drepturi de autor</a> © {new Date().getFullYear()} Eli Lilly and Company.</Paper>
                                                        </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      <Paper className='fTxt'>PP-LILLY-RO-0043</Paper>
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyURL  + "/ro/terms-and-conditions-of-use"} rel="noreferrer">Termeni de Utilizare</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/RO-ro/hcp"} rel="noreferrer">Politica de Confidențialitate</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.eliLillyURL  + "/accessibility-statement"} rel="noreferrer">Declarație de accesibilitate</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.eliLillyURL  + "/ro/contact/index.aspx"} rel="noreferrer">Contactați-ne</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Setări cookie</a>
                                                            </li>
                                                          </ul>
                                                        </Paper>
                                                        
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Poland" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>Strona jest dedykowana osobom powyżej 16 roku życia. Ta strona internetowa przeznaczona jest wyłącznie dla lekarzy.</Paper>
                                                        <Paper className='fTxt'>Copyright © {new Date().getFullYear()} Eli Lilly and Company.</Paper>
                                                        </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      <Paper className='fTxt'>PP-LILLY-PL-0072</Paper>
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={"/landing/poland/prawa-autorskie.html"} rel="noreferrer">Prawa autorskie</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPL + "/accessibility-statement"} rel="noreferrer">Oświadczenie o dostępności</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={"/landing/poland/regulamin-strony.html"} rel="noreferrer">Regulamin strony</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/PL-pl/hcp"} rel="noreferrer">Polityka prywatności i plików cookies</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPL + "/kontakt"} rel="noreferrer">Kontakt</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Ustawienia plików cookie</a></li>
                                                          </ul>
                                                        </Paper>
                                                        
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "UK" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>©{new Date().getFullYear()} Eli Lilly and Company. All rights reserved.</Paper>
                                                        <Paper className='fTxt'>Lilly is a registered trademark of Eli Lilly and Company.</Paper>
                                                        </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                     
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyURL  + "/uk/terms-of-use"}>Terms and Conditions</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/uk-en/hcp"} rel="noreferrer">Privacy Policy</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyURL  + "/uk/copyright"} rel="noreferrer">Copyright</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyURL  + "/uk/accessibility-statement"} rel="noreferrer">Accessibility Statement</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Cookie Settings</a>
                                                            </li>
                                                            
                                                          </ul>
                                                        </Paper>
                                                        <Paper className='fTxt'>October 2023 PP-MG-GB-0416</Paper>
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Israel" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='flist fTxt'>Copyright © {new Date().getFullYear()} <a href={config?.pdf} target="_blank">Eli Lilly and Company</a>. All rights reserved.</Paper>
                                                        
                                                        </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      <Paper className='fTxt'>PP-MG-IL-0069</Paper>
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.cloudfrontTermPdfIsrael}>Terms and Conditions</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/IL-en/hcp"} rel="noreferrer">Privacy Policy</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.cloudfrontCopyrightPdfIsrael} rel="noreferrer">Copyright</a>
                                                            </li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.cloudfrontAccessibilityPdfIsrael} rel="noreferrer">Accessibility Statement</a>
                                                            </li>
                                                          </ul>
                                                        </Paper>
                                                        
                                                      </Grid>
          
                                                    </Grid>
                                                    : country_name === "Italy" ?
                                                    <Grid container className='fTxtSec'>
                                                      <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                        <Paper className='fTxt'>Copyright ©{new Date().getFullYear()} Eli Lilly Italia S.p.A.-Società a socio unico Gruppo Eli Lilly and Company - Sede Legale: Via Gramsci 731/733 Sesto Fiorentino (FI) - PEC: direzione@pec.lilly.it Capitale Sociale Euro 69.921.731,00 i.v. – Reg. Imp. Trib. Firenze N. 14348 C.C.I.A.A. Firenze – Cod. Fisc. e Part. IVA 00426150488</Paper>
                                                        
                                                        </Grid>
                                                      <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                      <Paper className='flist fTxt'>PP-MG-IT-0286 – <a style={{textDecoration:'underline'}} href="/img/Italy_document.pdf" target='_blank'>RCP, prezzi e rimborsabilità</a></Paper>
                                                        <Paper className='fTxt'>
                                                          <ul className='flistOuter'>
                                                            <li className='flist'> <a target="_blank" href={config?.lillyURL3  + "site.net/it/lilly/termini-d-uso.html"} rel="noreferrer">Termini d'uso</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyPrivacy  + "/it-it/hcp"} rel="noreferrer">Informativa sulla privacy</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyURL3  + "site.net/it/lilly/copyright.html"} rel="noreferrer">Copyright</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a target="_blank" href={config?.lillyURL3  + "site.net/it/lilly/accessibilita.html"} rel="noreferrer">Accessibilità</a></li>
                                                            <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup} rel="noreferrer">Impostazioni dei cookie</a></li>
                                                          </ul>
                                                        </Paper>
                                                        
                                                      </Grid>
          
                                                    </Grid>
                                            :
                                            <Grid container className='fTxtSec'>
                                              <Grid item xs={12} lg={6} md={5} className='fcpyrghtSec'>
                                                <Paper className='fTxt'>©{new Date().getFullYear()} Eli Lilly and Company. All rights reserved</Paper>
                                                <Paper className='fTxt'>Lilly is a registered trademark of Eli Lilly and Company.</Paper>
                                              </Grid>
                                              <Grid item xs={12} lg={6} md={7} className='flistSec'>
                                                <Paper className='fTxt'>
                                                  <ul className='flistOuter'>
                                                    <li className='flist'> <a target="_blank" href={termsLink ?? ''} rel="noreferrer">Terms and Conditions</a></li>
                                                    <li className='flist'><span className='bar'>|</span> <a target="_blank" href={privacyLink ?? ''} rel="noreferrer">Privacy Policy</a></li>
                                                    <li className='flist'><span className='bar'>|</span> <a target="_blank" href={copyrightLink ?? ''} rel="noreferrer">Copyright</a></li>
                                                    <li className='flist'><span className='bar'>|</span> <a target="_blank" href={accessibilityLink ?? ''} rel="noreferrer">Accessibility Statement</a></li>
                                                    {cookieEnable ? <li className='flist'><span className='bar'>|</span> <a href='#' data-triggers-cookie-settings='true' onClick={openCookieSettingsPopup}>Cookie Settings</a></li> : ""}
                                                  </ul>
                                                </Paper>
                                                <Paper className='fTxt'>
                                                  {new Date().toLocaleDateString('default', { month: 'short' })} {new Date().getFullYear()} {vvpmApprovalCode}
                                                </Paper>
                                              </Grid>
                                            </Grid>}
          {/* End Footer Section */}
        </>
      }
    </>
  );

};

export default AppFooter;
