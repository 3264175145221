import React, { useState, useEffect, forwardRef } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import NoSsr from '@material-ui/core/NoSsr';



const Singleselect = forwardRef(({ open, list, title, handleSelected, tags, mandatory, disabled = false, placeholder, error = false }, ref) => {
  const [options, setOptions] = useState(list?.length > 0 ? list : []);
  useEffect(() => {
    setOptions(list?.length > 0 ? list : [])
  }, [list])
  const getSelectedItem = () => {
    if (tags.length > 0 && options.length > 0) {
      const item = options.find((opt) => {
        if (opt.label == tags[0].label)
          return opt;
      })
      return item || {};
    } else {
      return null;
    }
  }
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'single-select',
    options: options,
    getOptionLabel: (option) => option.label || '',
    onChange: (e, value) => { value == null || value == '' ? handleSelected([]) : handleSelected([value]) },
    value: getSelectedItem()
  });

  return (
    <>
      {open ?
        <div>
          <div className='filter-dropdown-popout'>
            <NoSsr>
              <div>
                <div {...getRootProps()}>
                  <label className={mandatory ? 'filterLabel mandatory' : 'filterLabel'} {...getInputLabelProps()} >
                    {title}
                  </label>
                  <div className={`filterInputWrapping ${disabled ? 'disabled' : ''} ${error ? 'error' : ''}`}>
                    <input disabled={disabled} placeholder={placeholder ? placeholder : `Select ${title}`}{...getInputProps()} ref={ref} />
                    <KeyboardArrowDownIcon style={{ color: '#d9d9d9', margin: 'auto' }} {...getInputProps()} />
                  </div>
                </div>
                {!disabled && groupedOptions.length > 0 ? (
                  <ul className='filterUl' {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                      <li {...getOptionProps({ option, index })}>
                        <span>{option.label}</span>
                        <CheckIcon fontSize="small" />
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </NoSsr>
          </div>
        </div>
        : ''}
    </>
  );
});

export default Singleselect;